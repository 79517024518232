import SignIn from "login/SignIn";
//import DistributorUser from "main/DistributorUser";


export default function LogInGateway ({user_type,accesstoken, children}) {

    const login_info = JSON.parse(window.localStorage.getItem("onedrop") || '{}');
    let accesstokenparam = accesstoken;
    return (
        <>
        {
            (login_info.login==='success' && login_info.user_type===user_type) 
            ? children
            : <SignIn/>
        }
        </>
    );
};

