
import React from 'react';

//import faker from 'faker';
import {randomColor, textSliced} from '__lib/aTools';

import { Typography, Checkbox, TableCell} from '@mui/material';
//import { styled } from '@mui/styles';
import styled from '@emotion/styled';
import {TableCellDense2} from "_components_table/TableElement";


import TableWithSortAndActionOnedrop from '_components_public/TableWithSortAndActionOnedrop';
//import  { TableCellDense2 } from "_components_element/TableElement";


//const headers = ['업체명', '코드','대표 담당자', '이메일', '전화번호','기기대수','키트판매량','키드사용량',];



/*
const TableCellDense2 = styled(TableCell)`
  padding: 2px 15px;  
  min-width : 35px;
`;
*/


const tableCellsOfRow = (row, labelId, isItemSelected) => (
  <>
    <TableCellDense2 padding="checkbox"  style={{ minWidth:55}}>
      <Checkbox
        color="primary"
        checked={isItemSelected}
        inputProps={{
          'aria-labelledby': labelId,
        }}        
      />
    </TableCellDense2>
    <TableCellDense2
      component="th"
      id={labelId}
      scope="row"
      padding="none"
      style={{padding: "0px 0px 0px 0px", minWidth:75, width:170}}
    >
      <div
        style={{
            width: '171x',
            height : '31px',
            backgroundColor:  row.color ,
            opacity: '1',
            borderRadius: '4px',
            overflow: 'hidden',     
            textAlign:'center', 
            padding : '0px 0px 0px 0px' ,
            margin : '0px 0px 0px 0px',
        }}                    
        >
        <Typography style={{fontSize:'15px', lineHeight:'31px', padding: '0px 0px 0px 0px' , margin:'0px 0px 0px 0px', color:'#222',  }}>
          {row.company}
        </Typography>
      </div> 
    </TableCellDense2>
    <TableCellDense2 align="left" style={{width:65}}>{row.code}</TableCellDense2>
    <TableCellDense2 align="left" style={{ minWidth:75}}>{row.contact}</TableCellDense2>
    <TableCellDense2 align="left" style={{ minWidth:85}}>{row.email}</TableCellDense2>
    <TableCellDense2 align="left" style={{ minWidth:90}}>{row.phone_num}</TableCellDense2>
    <TableCellDense2 align="left" style={{ minWidth:55}}>{row.cnt}</TableCellDense2>
    <TableCellDense2 align="left" style={{ minWidth:65}}>{row.sell}</TableCellDense2>
    <TableCellDense2 align="left" style={{ minWidth:65}}>{row.use}</TableCellDense2>          
  </>
);




const vendorInfo = (data) => {
  if (!data || Object.keys(data).length===0) {
    return [];
  };

  return [...Array(1).keys()].map(
    (_, index1) => data.map(
      (row,index2) => ({
        idx : '',
        company : textSliced(row.name, 21), //`${row.name}`.slice(0,25),
        code : row.code,
        contact :  row.representative_name,
        email :  row.email,
        phone_num :  row.phone,
        cnt :  row.device_holding_quantity,
        sell :  row.kit_shipped_quantity,
        use : row.kit_used_quantity,
        color : row.color.replace('0x','#') , //randomColor() || ((row.color) ? row.color.replace('0x','#') : randomColor()),
        nid: index1*100+index2,
      })
      )
    ).flat();
};


const filterByVender = (_list, vendor) => _list.filter( row => !vendor || row.company.includes(vendor) || row.code.includes(vendor));
const filterByContact = (_list, contact) => _list.filter( row => !contact || row.contact.includes(contact));

export default function TableDistributorInfo({location_vendor, as_vendor='', as_contact='' ,textLangdistributorrVendors, textLangtable, props}) {




  const _info = location_vendor.vendor;
  const rows = filterByVender( filterByContact( vendorInfo(_info), as_contact), as_vendor);

let headerCells = [
    {
        idx: 'company',
        numeric: false,
        disablePadding: true,
        label: textLangdistributorrVendors.colum1,
    },
    {
        idx: 'code',
        numeric: false,
        disablePadding: true,
        label: textLangdistributorrVendors.colum2,
    },
    {
        idx: 'contact',
        numeric: false,
        disablePadding: true,
        label: textLangdistributorrVendors.colum3,
    },
    {
        idx: 'email',
        numeric: false,
        disablePadding: false,
        label: textLangdistributorrVendors.colum4,
    },
    {
        idx: 'phone_num',
        numeric: false,
        disablePadding: false,
        label: textLangdistributorrVendors.colum5,
    },
    {
        idx: 'cnt',
        numeric: false,
        disablePadding: false,
        label: textLangdistributorrVendors.colum6,
    },
    {
        idx: 'sell',
        numeric: false,
        disablePadding: false,
        label: textLangdistributorrVendors.colum7,
    },
    {
        idx: 'use',
        numeric: false,
        disablePadding: false,
        label: textLangdistributorrVendors.colum8,
    },
];

  return (
    <>
    <TableWithSortAndActionOnedrop table_title={textLangdistributorrVendors.title} rows={rows} headerCells={headerCells} tableCellsOfRow={tableCellsOfRow} props={props} textLangtable={textLangdistributorrVendors}/>
    </>
  )
};

