import {createContext, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {
    Box,
    TextField,
    TextFieldwithBG,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Menu,
    MenuItem,
    TypographyA
} from "__ui_components/UIComponents";

import OnedropCSArticleBoardDetail from "./onedropCSArticleBoardDetail"

import {MapIcon, PersonOutlineIcon} from "../__icons/IconProvider";

import ComponentArticle from 'article/onedropCSArticleBoard';

import ComponentArticleDetail from "./onedropCSArticleBoardDetail";
import ComponentArticleModify from "./onedropCSArticleBoardModify";
import ComponentArticleWrite from "./onedropCSArticleBoardWrite";

//import './onedropCSArticleBoardMain.module.css';

const ARTICLE_ITEMS = {
    'OnedropCSArticleBoard': {
        jsx: <ComponentArticle/>,
        label: 'CS게시판리스트',
        icon: PersonOutlineIcon,
    },
    'OnedropCsArticleBoardDetail': {
        jsx: <ComponentArticleDetail/>,
        label: 'CS게시판상세',
        icon: PersonOutlineIcon,
    },


    'OnedropCSArticleBoardModify': {
        jsx: <ComponentArticleModify/>,
        label: 'CS게시판수정',
        icon: PersonOutlineIcon,
    },
    'OnedropCSArticleBoardWrite': {
        jsx: <ComponentArticleWrite/>,
        label: 'CS게시판작성',
        icon: PersonOutlineIcon,
    }
}



export const ArticleContext = createContext({
    articletypeSelected: () => {},
    setarticletypeSelected: () => {},
    articleSeqSelected: () => {},
    setarticleSeqSelected: () => {},
    articleindexSelected: () => {},
    setarticleindexSelected: () => {}

});

export default function ComponentArticleMain() {
    const ARTICLE_LIST_BY_USER_TYPE =['OnedropCSArticleBoard','OnedropCSArticleBoardDelete','OnedropCSArticleBoardDetail','OnedropCSArticleBoardModify','OnedropCSArticleBoardWrite'];
    const [articletypeSelected, setarticletypeSelected] = useState(ARTICLE_LIST_BY_USER_TYPE[0]);
    const [articleSeqSelected, setarticleSeqSelected] = useState();
    const [articleindexSelected, setarticleindexSelected] = useState();

    const value = useMemo(() => ({ articletypeSelected, setarticletypeSelected,articleSeqSelected, setarticleSeqSelected,articleindexSelected,setarticleindexSelected }), [articletypeSelected, setarticletypeSelected,articleSeqSelected, setarticleSeqSelected,articleindexSelected,setarticleindexSelected]);


        return (
            <ArticleContext.Provider value={value}>
            <>
                    {ARTICLE_ITEMS[articletypeSelected].jsx}
            </>
            </ArticleContext.Provider>
        )

}