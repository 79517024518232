import * as React from 'react';

import { Box,  Card, CardContent, CardActions, Typography, TextCardTitle,  Divider} from "__ui_components/UIComponents";


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const CardBodyDefault = (
    <>
    <Typography variant="h5" component="div">
    be{bull}nev{bull}o{bull}lent
  </Typography>
  
  <Typography sx={{ mb: 1.5 }} color="text.secondary">
    adjective
  </Typography>
  <Typography variant="body2">
    well meaning and kindly.
    <br />
    {'"a benevolent smile"'}
  </Typography>
  </>
);

// <Typography sx={{ fontSize: 14, paddingTop:1 }} color="#192A3E" gutterBottom> 

export default function BasicCard({card_title, card_body, style={}, TEST_RESULT_TYPE}) {
  
     return (
    <Card sx={{ minWidth: 200, maxWidth:1200 }} style={style}>
      <div align='left' style={{padding:'3px 0 3px 20px'}}>
        <TextCardTitle > 
         {card_title}
        </TextCardTitle>
      </div>
      <Divider />

      <CardContent>
          {
          (card_body)
          ? card_body
          : CardBodyDefault
        }
      </CardContent>

      </Card> 
  );
}

//<TextCardTitle style={{fontFamily:'Noto Sans KR', fontWeight:500, fontStyle:'normal', fontSize : '15px', fontColor: "#192A3E",}}> 