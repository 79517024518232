

import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getDaysArray, randomBetween } from '__lib/aTools';


const date_range = getDaysArray(new Date("2021-12-01"),new Date("2021-12-31"));
const sample_data = date_range.map( dt => {
  return (
    {
      x : dt.toISOString().slice(0,10),
      value : randomBetween(100,1000),
      x_label : dt.toISOString().slice(0,10),
      description : '',
    }
  );
});


export default function AreaChart_1 ({
    data = sample_data,
    width=500, height=210, 
    line_type='natural', 
    dot={ stroke: 'blue', strokeWidth: 2 } 
  }) {

  const max_value = Math.max(...data.map(row => row.value));

  //const dump = ((Math.floor(max_value/1000)) || 1) *100
  //const domain_y_max = Math.round((max_value*1.5)/dump) * dump;
  const domain_y_max = Math.floor(max_value*1.3)+1;

  const LINE_COLOR = ' #467fcf ';
  return (
    <div width="100%" height="100%">
      <AreaChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          >
        <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={LINE_COLOR} stopOpacity={0.8}/>
              <stop offset="95%" stopColor={LINE_COLOR} stopOpacity={0}/>
            </linearGradient>
        </defs>

        <CartesianGrid strokeDasharray="1 1" horizontal={false}/>
        <XAxis dy={4} dataKey="x" axisLine={true} tick={{fontSize:14}} tickLine={false} />
        <YAxis 
            domain={[0,domain_y_max]} 
            tick={{fontSize:15}} hide={false} tickLine={false} axisLine={false}           
            
            />
        <Tooltip />
        <Area 
            type={line_type} dataKey="value" 
            stroke={LINE_COLOR} strokeWidth={4}  
            fillOpacity={1} fill="url(#colorPv)" 
            dot={ dot }
            />
      </AreaChart>
    </div>
  );

};
