
import {TEST_RESULT_TYPE} from "__config/dashboardConfig";

import {useEffect, useState} from 'react';


import { useFieldTestCenter } from "__api/requestOneDropData";

import {Box, Button} from "__ui_components/UIComponents";

import SelectItem from "_components_public/SelectItem";
import TableTestCenterOnedrop from '_components_table/TableTestCenterOnedrop';
import {SiteContext, useContext} from "../__context_api/SiteContext";

function InputFormSearchSite03({ department_to_select, handleConfirm, textLangSitesearhinput, testresulttype}) {

    const test_result_to_select = Object.keys(TEST_RESULT_TYPE);

    const [department, setDepartment] = useState('');
    const [testResult, setTestResult] = useState('');

    const onClickConfirm = () => {

        handleConfirm({
            department : (department == "All" || department == "전체") ? '': department,
            test_result : (testResult == "All" || testResult == "전체")   ? '' : testResult
        });
    };

    const handleOnKeyPress = e =>{
        if(e.key === 'Enter') {
            onClickConfirm();
        }
    }

    return (
            <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '200px'  },
                  display : 'flex',
                }}
                noValidate
                autoComplete="off"
            >
            <SelectItem input_label={textLangSitesearhinput.box1} value={department} item_to_select={department_to_select}  handleChange={setDepartment} is_set_all={true} textLangSitesearhinput={textLangSitesearhinput}/>
            <SelectItem input_label={textLangSitesearhinput.box2} value={testResult} item_to_select={test_result_to_select} onKeyPress={() => handleOnKeyPress()}   handleChange={setTestResult} is_set_all={true} textLangSitesearhinput={textLangSitesearhinput} testresulttype={testresulttype} testreusltlang={textLangSitesearhinput} fromObj={"testcenter"} />
            <Button sx={{maxWidth:50}} variant='contained' onClick={()=>onClickConfirm()}>{textLangSitesearhinput.submit}</Button>

            </Box>
        );
};

const test_result_mapFiltered = (test_result_of_testcenter, filterDate, filterNotice) => test_result_of_testcenter.filter((x) => {
    const today = new Date();

    const year = today.getFullYear(); // 년
    const month = today.getMonth();   // 월
    const day = today.getDate();
    const timeHour = today.getHours();
    const timeMinute = today.getMinutes();
    const timeSec = today.getSeconds();


    if(filterDate !== -1 && filterNotice !== -1){

        if(filterNotice === 1 && x.notified === 'O'){
            if(filterDate === 0 && x.notified === 'O'){return x;}
            if(filterDate === 1 && x.notified === 'O' && new Date(x.date +" "+ x.test_time).getTime() - new Date(year, month, day - 1,timeHour,timeMinute,timeSec).getTime() > 0){
                return x;
            }
            if(filterDate === 2 && x.notified  === 'O' && new Date(x.date +" "+ x.test_time).getTime() - new Date(year, month, day - 7,timeHour,timeMinute,timeSec).getTime() > 0){
                return x;
            }
            if(filterDate === 3 && x.notified === 'O' && new Date(x.date +" "+ x.test_time).getTime() - new Date(year, month-1, day, timeHour,timeMinute,timeSec).getTime() > 0){
                return x;
            }
        }else if(filterNotice === 2){
            if(filterDate === 0 && x.notified === 'X'){return x;}
            if(filterDate === 1 && x.notified  === 'X' && new Date(x.date+" "+ x.test_time).getTime() - new Date(year, month, day - 1,timeHour,timeMinute,timeSec).getTime() > 0){
                return x;
            }
            if(filterDate === 2 && x.notified === 'X' && new Date(x.date+" "+ x.test_time).getTime() - new Date(year, month, day - 7,timeHour,timeMinute,timeSec).getTime() > 0){
                return x;
            }
            if(filterDate === 3 && x.notified === 'X' && new Date(x.date+" "+ x.test_time).getTime() - new Date(year, month-1, day,timeHour,timeMinute,timeSec).getTime() > 0){
                return x;
            }
        }else if(filterNotice === 0 ){


            if(filterDate === 0 ){return x;}
            if(filterDate === 1 && new Date(x.date+" "+ x.test_time).getTime() - new Date(year, month, day - 1,timeHour,timeMinute,timeSec).getTime() > 0){
                return x;
            }
            if(filterDate === 2  && new Date(x.date+" "+ x.test_time).getTime() - new Date(year, month, day - 7,timeHour,timeMinute,timeSec).getTime() > 0){
                return x;
            }
            if(filterDate === 3  && new Date(x.date +" "+ x.test_time).getTime() - new Date(year, month-1, day,timeHour,timeMinute,timeSec).getTime() > 0){
                return x;
            }
        }
    }else{//초기
      return x;
    }
});


const testResultOfTestCenter = (result) => {
    if (!result || Object.keys(result).length===0) {
        return [];
    };
    const test_result_map = Object.keys(TEST_RESULT_TYPE);
    return result.map( 
        row => (
            {
                date : row.date,
                staff_code : row.staff_code,     
                department_name : row.department_name ,    
                name : row.name,    
                test_result : test_result_map[row.result] ,
                test_time : row.time,   
                notified : (row.notified) ? 'O' : 'X',
            }
        ));
};

const filterByDepartmentAndTestResult = (_list, dep, res) => _list.filter(
    row => (
        (!dep || row.department_name === dep) 
        && (!res || row.test_result === res) 
    )
);

export default function OneDrop03TestCenter ({defultlang, TEST_RESULT_TYPE}) {
    let { login_info, lang, textOflang}  = useContext(SiteContext);
    let initial_params = {};

    const [analysislang,setanalysislang] = useState(defultlang);
    let textlang = textOflang[defultlang];
    let textLangSiteTestcenter = textlang.site.testcenter;
    let textLangtable = textlang.site.testcenter.table;
    let testresulttype = textlang.testresulttype2;

    useEffect(() => {
        return () =>  null;

    },[]);
    if(login_info.site_key){
        if(login_info.site_key == 'onedrop'){
            initial_params = {
                "site_key": login_info.site_key  ,
                "page" : 0,
                "max_items" : 10000,
                "max_entries" : 2000,
                "entries" : 2000,
                "user_id_contains" : '1drop_'
            };
        }else{
            initial_params = {
                "site_key": login_info.site_key  ,
                "page" : 0,
                "max_items" : 10000,
                "max_entries" : 2000,
                "entries" : 2000,
                "user_id_contains" : login_info.site_key + '_'
            };
        }
    }else {
            initial_params = {
                "site_key": undefined,
                "page" : 0,
                "max_items" : 10000,
                "max_entries" : 2000,
                "entries" : 2000

            };
    }

    const [is_loading, field_testcenter] = useFieldTestCenter(initial_params);

    const department_to_select = [...new Set(testResultOfTestCenter(field_testcenter.result).map(row => row.department_name))];
    const [searchOption, setSearchOption] = useState({
        department : '', //"2020/01/01",
        test_result : '', //"2022/12/31"
    });

    const [filterDate,setfilterDate] = useState(-1);
    const [filterNotice,setfilterNotice] = useState(-1);
    const [test_result_of_testcenterParam, settest_result_of_testcenterParam] = useState();
    const setfilterDateFun = (param) =>
        setfilterDate(param);
    const setfilterNoticeFun = (param) =>
        setfilterNotice(param);
    const onSetSearchOption = (vv) => {

        setSearchOption(vv);
    };

    function isEmptyObj(obj)  {
        if(typeof obj === Object){
            if(obj.constructor === Object
                && Object.keys(obj).length === 0)  {
                return true;
            }
        }
        return false;
    }
    useEffect(() => {
            if(isEmptyObj(field_testcenter) != true){
                settest_result_of_testcenterParam( test_result_mapFiltered (filterByDepartmentAndTestResult(testResultOfTestCenter(field_testcenter.result), searchOption.department, searchOption.test_result),filterDate, filterNotice));
    }; return () => null;},[ field_testcenter,filterDate,filterNotice,searchOption,]);

    return (
        <>
            <div style={{paddingBottom:20}}  >
                <InputFormSearchSite03 department_to_select={department_to_select} handleConfirm={onSetSearchOption} textLangSitesearhinput={textLangSiteTestcenter.serchinputbox} testresulttype={testresulttype}/>
            </div>
            <div style={{width:'1040px', }} >
                {(!is_loading) ?
                <TableTestCenterOnedrop  testcenter_data={test_result_of_testcenterParam} filterDate={filterDate} filterNotice={filterNotice} setfilterDate={setfilterDateFun} setfilterNotice={setfilterNoticeFun} settest_result_of_testcenterParam={settest_result_of_testcenterParam} textLangSitetable={textLangSiteTestcenter.table} textLangSitetablefilter={textLangSiteTestcenter.tablefilterbox} TEST_RESULT_TYPE={TEST_RESULT_TYPE} textLangSitetable={textLangSiteTestcenter.table} textLangtable={textLangtable}/> : ''}
            </div>
        </>
    )
}