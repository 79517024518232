
import './App.css';

import React from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Entry from "main/Entry";


function App() {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.async = true;
    document.body.appendChild(script);
  return (

    <BrowserRouter>
      <div className="App" style={{minHeight: '100vh', width:'100vw', backgroundColor:'#f5f5f5'}}>
      <Entry />
    </div>


    </BrowserRouter>
  );
}

export default App;
