import React from "react";
const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }
    return (
        <div style={{display: "inline-flex"}}>
            <nav>
                <div className="pagination">
                    {pageNumbers.map((number) => (
                        <ul key={number} className="page-item">
                            <ui onClick={() => paginate(number)} className="page-link">
                                {number}
                            </ui>
                        </ul>
                    ))}
                </div>
            </nav>
        </div>
    );
};

export default Pagination;