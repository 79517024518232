import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {
    Box,
    TextField,
    TextFieldwithBG,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Menu,
    MenuItem,
    TypographyA
} from "__ui_components/UIComponents";
//import "./onedropCSArticleBoard.module.css"
import SelectItem from "_components_public/SelectItem";
import {useGetBoardList, useFieldTestCenter} from "../__api/requestOneDropData";
import axios from "axios";
import OnedropCSArticleBoardDetail from "./onedropCSArticleBoardDetail"
import Onedrop03TestCenter from "../containers/oneDrop03TestCenter";
import {MapIcon, PersonOutlineIcon} from "../__icons/IconProvider";
import Onedrop02Employee from "../containers/oneDrop02Employee";
import ComponentArticleDetail from "./onedropCSArticleBoardDetail"
import OnedropCsArticleBoardDetail from "./onedropCSArticleBoardDetail"

import ARTICLE_ITEMS from "../main/SideNavigation"
import {ArticleContext} from "./onedropCSArticleBoardMain";
import Pagination from "react-js-pagination";
//import Pagination from "./Pagination";
import Posts from "./Posts";
import {DistributorContext} from "../__context_api/DistributorContext";
import {SiteContext} from "../__context_api/SiteContext";

import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';




export default function ComponentArticleList() {
    const [posts, setPosts] = useState([]);
    const { articletypeSelected, setarticletypeSelected,articleSeqSelected, setarticleSeqSelected, articleindexSelected,setarticleindexSelected } = useContext(ArticleContext);
    const { login_info } = useContext(SiteContext);
    const [is_loading, BoardList] = useGetBoardList();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [page, setPage] = useState(1);



    function setarticleSeqSelectedFun(setarticleSeqSelected,seq,setarticleindexSelected,index){
        setarticleSeqSelected(seq);
        setarticleindexSelected(index);
        return null;
    }

    function isEmptyObj(obj)  {
        if(typeof obj === Object){
            if(obj.constructor === Object
                && Object.keys(obj).length === 0)  {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        if(isEmptyObj(BoardList) != true && Object.keys(BoardList).length >0) {
            let refinedpost ;
            refinedpost = BoardList.articlelist;
            if (login_info.site_key == 'onedrop') {
                setPosts(refinedpost.sort().reverse());
            } else {
                if (BoardList.articlelist != undefined && BoardList.articlelist != null && BoardList.articlelist.length >0) {
                    refinedpost = BoardList.articlelist;
                    setPosts(refinedpost.filter(list => list.writer == login_info.site_key).sort().reverse());

                }
            }

        } return () => {};
        },[BoardList ]);




    /* 새로 추가한 부분 */
    const indexOfLast = currentPage * postsPerPage;
    const indexOfFirst = indexOfLast - postsPerPage;

    const currentPosts = (posts) => {
        let currentPosts = 0;
        currentPosts = posts.slice(indexOfFirst, indexOfLast);
        return currentPosts;
    };
    // const Paging = () => {
    //     const [page, setPage] = useState(1);
    // }
    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage);
    };



    if (posts != null && posts.length > 0) {

        return (


                 <>
                {(!is_loading) ?
                   <Container style={{width:"1080px",height:"500px"}}>
                       <div>
                        <nav>
                         <Posts posts={currentPosts(posts)} loading={is_loading}
                                setarticleSeqSelectedFun={setarticleSeqSelectedFun}
                                setarticleSeqSelected={setarticleSeqSelected}
                                setarticletypeSelected={setarticletypeSelected}
                                login_info={login_info}
                                totalPosts={posts.length}
                                currentPage={currentPage}
                                postsPerPage={postsPerPage}
                                setarticleSeqSelectedFun={setarticleSeqSelectedFun}
                                setarticleindexSelected={setarticleindexSelected}
                         ></Posts>
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={10}
                                totalItemsCount={posts.length}
                                pageRangeDisplayed={10}
                                prevPageText={"‹"}
                                nextPageText={"›"}
                                onChange={handlePageChange}
                            />

                        </nav>
                    </div>
                    </Container>

                    : ''}
                    <Button  style={{float:"right"}} value = "처음"  onClick={() => setCurrentPage(1)}></Button>
                    <Button  style={{float:"right"}} value = "글쓰기" onClick={() => setarticletypeSelected('OnedropCSArticleBoardWrite')}></Button>

            </>)

    }else {
        return (
            <>
                <Table bordered  >
                        <thead>
                        <tr>
                            <th  >번호</th>
                            <th  >제목</th>
                            <th  >글쓴이</th>
                            <th  >날짜</th>
                        </tr>
                        </thead>
                        <tbody>
                                <tr>
                                    <td   colSpan={4}><div>테이블의 데이터가 없습니다.</div></td>
                                </tr>
                        </tbody>
                    </Table>



                <input type = "button" value = "리스트로" ></input>
                <input type = "button" value = "글쓰기" onClick={() => setarticletypeSelected('OnedropCSArticleBoardWrite')}></input>
                <input type = "button" value = "삭제"></input>
            </>
        );
    }
}