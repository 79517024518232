
import React, {useEffect, useState} from 'react';

//import {randomColor} from '__lib/aTools';
//import {TEST_RESULT_CASE} from "__config/dashboardConfig";


import { Typography, Checkbox, Button} from '@mui/material';
//import FaceIcon from '@mui/icons-material/Face';

import TableWithSortAndActionOnedrop from '_components_public/TableWithSortAndActionOnedrop';
import {TableRowDense2, TableCellDense2} from "_components_table/TableElement";

import ChipOfTestResult from "_components_public/ChipOfTestResult";
import {ko} from "date-fns/locale";
//검사결과 아이콘

export default function TableTestCenterOnedrop({testcenter_data, employee_data,tableTestCenterOnClick, filterDate ,filterNotice , setfilterDate, setfilterNotice, settest_result_of_testcenterParam, textLangSitetable, textLangSitetablefilter, TEST_RESULT_TYPE ,textLangtablefilterbox,props, textLangtable}) {
    let textLangtablefilterboxObj = {textLangSitetablefilter};
    let textLangtableObj = {textLangtable};

    const rows = testcenter_data.map((row, index) => (
    {
      ...row,
      nid : index,

      datetime:row.date + " " + row.test_time,
    }
  ));


  const headerCells = [
    {
      idx: 'datetime',
      numeric: false,
      disablePadding: true,
      label: textLangSitetable.colum1,
      align: "center",
        "text-align":"center"
    },
    {
      idx: 'staff_code',
      numeric: false,
      disablePadding: true,
      label: textLangSitetable.colum2,
    },
    {
      idx: 'department_name',
      numeric: false,
      disablePadding: true,
      label: textLangSitetable.colum3,
    },
    {
      idx: 'name',
      numeric: false,
      disablePadding:  true,
      label: textLangSitetable.colum4,
    },
    {
      idx: 'test_result',
      numeric: false,
      disablePadding:  true,
      label: textLangSitetable.colum5
    },
    // {
    //   idx: 'test_time',
    //   numeric: false,
    //   disablePadding:  true,
    //   label: '',
    //   hidden: true
    // },
    {
      idx: 'notified',
      numeric: false,
      disablePadding:  false,
      label: textLangSitetable.colum6,
    },
  ];


  const tableCellsOfRow = (row, labelId, isItemSelected) => (
    <>
      <TableCellDense2 padding="checkbox" style={{width:50, }} >
        <Checkbox
          color="primary"
          checked={isItemSelected}          
          inputProps={{
            'aria-labelledby': labelId,
          }}
        />       
      </TableCellDense2>
      <TableCellDense2            
            id={labelId}
            scope="row"
            align="left"
            padding="none"
            style={{width:130, }}
          >{row.date + " " + row.test_time}</TableCellDense2>
      <TableCellDense2 align="left" style={{width:80, }}><Typography variant='h7' sx={{ fontWeight: 'bold' }}>{row.staff_code }</Typography></TableCellDense2>
      <TableCellDense2 align="left" style={{width:160, }}>{row.department_name}</TableCellDense2>
      <TableCellDense2 align="left" style={{width:100, }}>{row.name}</TableCellDense2>
      <TableCellDense2 align="left" style={{width:120, }}><ChipOfTestResult idx={row.test_result} TEST_RESULT_TYPE2={TEST_RESULT_TYPE}/></TableCellDense2>
      {/*<TableCellDense2 align="left" style={{width:70, }}></TableCellDense2>*/}
      <TableCellDense2 align="left" style={{width:60, }}>{row.notified}</TableCellDense2>
    </>
  );

  return (
    <>
    <TableWithSortAndActionOnedrop table_title={textLangSitetable.title} rows={rows} headerCells={headerCells} filterDate={filterDate} filterNotice={filterNotice}
                            setfilterDate={setfilterDate} setfilterNotice={setfilterNotice} tableTestCenterOnClick={tableTestCenterOnClick} tableCellsOfRow={tableCellsOfRow} settest_result_of_testcenterParam={settest_result_of_testcenterParam} textLangSitetablefilter={textLangSitetablefilter} textLangtable={textLangtable} textLangtablefilterbox={textLangtablefilterboxObj} />
    </>
  )
};