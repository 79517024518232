


let lang = "ko-KR";
//let lang = "en-US"
let defaultLang = lang;

export const langTextForDashboard = {
    "ko-KR": {
        site: {
            header: {text1: "1drop", text2: "분석"},
            sidenavi: {menu1: "분석", menu2: "테스트센터", menu3: "멤버"},
            analysis: {

                serchinputbox: {
                    box1: "부서를 선택하세요",
                    box2: "기간",
                    box3: "시작 날짜",
                    box4: "끝 날짜",
                    submit: "검색",
                    boxLabel1: "전체"
                },
                resultbox: {
                    box1: {title: "검사결과", text1: "총 검사 진행 수", error1: "데이터가 없습니다."},
                    box2: {title: "검사 진행 수", text1: "", error1: "데이터가 없습니다."},
                    box3: {title: "주기별 검사결과 및 진행률", text1: "", error1: "데이터가 없습니다."},
                    box4: {title: "검사진행률", text1: "", error1: "데이터가 없습니다."},
                    box5: {title: "양성 결과", text1: "", error1: "데이터가 없습니다."},
                    box6: {title: "팀별 테스트 커버리지", text1: "검사 수",text2:"예상 검사 수",text3: "개",text4:"명", error1: "데이터가 없습니다."},
                    error1: "데이터가 없습니다."
                }

            },
            "testcenter": {

                serchinputbox: {
                    box1: "부서를 선택하세요",
                    box2: "검사결과를 선택하세요",
                    submit: "검색",
                    boxLabel1: "전체",
                    boxLabel2: "전체",
                    spectilvalue :"",
                    testresult:["음성","양성","불확정","무효","취소"],
                    testresultorigin:["전체", "negative","positive","inconclusive","invalid","cancel"]

                },
                table: {
                    title: "테스트센터 현황",
                    colum1: "날짜 시간",
                    colum2: "사번",
                    colum3: "부서",
                    colum4: "이름",
                    colum5: "검사 결과",
                    colum6: "통 보",
                    page: "페이지 당 행 수",
                    error1: "데이터가 없습니다."
                },
                tablefilterbox: {
                    box1: {title: "날짜 시간", input1: "전체", input2: "하루", input3: "일주일", input4: "한달"},
                    box2: {title: "통보", input1: "전체", input2: "O", input3: "X"},
                    button: {text1: "적용", text2: "닫기"}
                }
            },
            employee: {

                serchinputbox: {

                    box1: "부서를 선택하세요",
                    box2: "사번 또는 이름을 입력하세요",
                    submit: "검색",
                    boxLabel1: "전체",
                    boxLabel2: "전체",

                },
                table: {
                    title: "직원 검사 현황",
                    colum1: "사번",
                    colum2: "이름",
                    colum3: "부서",
                    colum4: "직급",
                    colum5: "최근 테스트",
                    colum6: "성별",
                    colum7:"연락하기",
                    page: "페이지 당 행 수",
                    error1: "데이터가 없습니다."
                },
                tablefilterbox: {
                    box1:{title:"직급"},
                    box2:{title:"최근테스트",value1:"전체",value2:"음성",value3:"양성",value4:"불확정",value5:"무효",value6:"취소"},
                    box3:{title:"성별", input1:"전체",input2:"male",input3:"female"},
                    button:{text1:"적용",text2:"닫기"}
                }
            }


        },
        distributor: {
            header: {text1: "1drop", text2: "분석"},
            sidenavi: {menu1: "분석", menu2: "지역 맵", menu3: "판매처"},
            analysis: {

                serchinputbox: {
                    box1: "판매처를 입력하세요",
                    box2: "기간",
                    box3: "시작 날짜",
                    box4: "끝 날짜",
                    submit: "검색",
                    boxLabel1: "전체"
                },
                resultbox: {
                    box1: {title: "검사결과", text1: "총 검사 진행 수",error1: "데이터가 없습니다.",
                        testresulttype:{
                            label1:"음성",
                            label2:"양성",
                            label3:"불확정",
                            label4:"무효",
                            label5:"취소",
                        }
                    },
                    box2: {title: "판매처 사용갯수", text1: "사용량", text2:"개", error1: "데이터가 없습니다."},
                    box3: {title: "기간별 사용추이", text1: "",error1: "데이터가 없습니다." },
                    box4: {title: "해당 기간 판매처 사용갯수", text1: "",error1: "데이터가 없습니다." },
                    error1: "데이터가 없습니다."
                }
            },
            regionmap: {
                serchinputbox: {
                    box1: "판매처를 입력하세요",
                    box2: "기간",
                    box3: "시작 날짜",
                    box4: "끝 날짜",
                    submit: "검색",
                    boxLabel1: "전체"
                },
                map: {button1: "지도", button2: "위성", button3: "지형", button4: "라벨", button5: "현재 위치"},
                resultbox: {
                    box1: {title: "검사결과", text1: "총 검사 진행 수", error1:"등록된 벤더가 없습니다."}
                }
            },
            vendors: {
                table: {
                    title: "판매처별 현황",
                    colum1: "업체명",
                    colum2: "코드",
                    colum3: "대표담당자",
                    colum4: "이메일",
                    colum5: "전화번호",
                    colum6: "기기대수",
                    colum7: "키트판매량",
                    colum8: "키트사용량",
                    page: "페이지 당 행 수",
                    error1:"데이터가 없습니다."
                },
                serchinputbox: {
                    box1: "판매처를 입력하세요",
                    box2: "담당자를 입력하세요",
                    submit: "검색",
                    boxLabel1: "전체",
                    error1:"데이터가 없습니다."
                }
            }

        },testresulttype:{
            label1:"음성",
            label2:"양성",
            label3:"불확정",
            label4:"무효",
            label5:"취소",
        },testresulttype2: ["음성","양성","불확정","무효","취소"]

    },

        "en-US": {
            site: {
                header: {text1: "1drop", text2: "Analysis"},
                sidenavi: {menu1: "Analysis", menu2: "TestCenter", menu3: "Employee"},
                analysis: {

                    serchinputbox: {
                        box1: "select a department",
                        box2: "Period",
                        box3: "Start Date",
                        box4: "End Date",
                        submit: "Search",
                        boxLabel1: "All"
                    },
                    resultbox: {
                        box1: {title: "Inspection results", text1: "  Total Number",error1: "No Data"},
                        box2: {title: "Number of inspection progresses", text1: "", error1: "No Data"},
                        box3: {title: "Inspection results and progress by cycle", text1: "", error1: "No Data"},
                        box4: {title: "Inspection progress rate", text1: "", error1: "No Data"},
                        box5: {title: "positive result", text1: "", error1: "No Data"},
                        box6: {title: "Test Coverage by Team", text1: "Performed Number",text2:"Expected Number",text3:"tests",text4:"persons", error1: "No Data"},
                        error1: "No Data"
                    }
                },

                testcenter: {

                    serchinputbox: {
                        box1: "select a department",
                        box2: "Select test result",
                        submit: "Search",
                        boxLabel1: "All",
                        boxLabel2: "All",
                        spectilvalue :"",
                        testresult:[ "Negative","Positive","Inconclusive","Invalid","Cancel"],
                        testresultorigin:["All", "negative","positive","inconclusive","invalid","cancel"]
                    },
                    table: {
                        title: "Testcenter status",
                        colum1: "Date time",
                        colum2: "Staff code",
                        colum3: "Department",
                        colum4: "Name",
                        colum5: "Inspection result",
                        colum6: "notified",
                        page: "Rows per page",
                        error1: "No Data"
                    },
                    tablefilterbox: {
                        box1: {
                            title: "Date within",
                            input1: "All",
                            input2: "a day",
                            input3: "a week",
                            input4: "a month"
                        },
                        box2: {title: "notification", input1: "All", input2: "O", input3: "X"},
                        button: {text1: "apply", text2: "close"}
                    }
                },

                employee: {

                    serchinputbox: {

                        box1: "select a department",
                        box2: "Enter employee number or name",
                        submit: "Search",
                        boxLabel1: "All",
                        boxLabel2: "All",

                    },
                    table: {
                        title: "Employee Inspection Status",
                        colum1: "Staff Code",
                        colum2: "Name",
                        colum3: "Department",
                        colum4: "Position",
                        colum5: "Recent Test",
                        colum6: "Gender",
                        colum7: "Contact",
                        page: "Rows per page",
                        error1: "No Data"
                    },
                    tablefilterbox:{
                        box1:{title:"Position"},
                        box2:{title:"Recent Test",value1:"All",value2:"Negative",value3:"Positive",value4:"Inconclusive",value5:"Invalid",value6:"Cancel"},
                        box3:{title:"Gender",input1:"All",input2:"male",input3:"female"},
                        button:{text1:"Apply",text2:"Close"}
                    }
                }
            },

            distributor: {
                header: {text1: "1drop", text2: "Analysis"},
                sidenavi: {menu1: "Analysis", menu2: "Location Map", menu3: "Distributor"},
                analysis: {

                    serchinputbox: {
                        box1: "Please enter vendor",
                        box2: "Period",
                        box3: "Start Date",
                        box4: "End Date",
                        submit: "Search",
                        boxLabel1: "All"
                    },
                    resultbox: {
                        box1: {title: "Inspection results", text1: "  Total Number", error1: "No Data",
                            testresulttype:{
                                label1:"Negative",
                                label2:"Positive",
                                label3:"Inconclusive",
                                label4:"Invalid",
                                label5:"Cancel",
                            }},
                        box2: {title: "Number of used kit For vendors", text1: "used kit Number",text2:"kits", error1: "No Data"},
                        box3: {title: "Showing trend using number of used kit By graphe", text1: "" ,error1: "No Data"},
                        box4: {title: "Number of used kit by vendor during the period", text1: "", error1: "No Data"},
                        error1: "No Data"
                    }
                },
                regionmap: {
                    serchinputbox: {
                        box1: "Please enter vendor",
                        box2: "Period",
                        box3: "Start Date",
                        box4: "End Date",
                        submit: "Search",
                        boxLabel1: "All"
                    },
                    map: {
                        button1: "Map",
                        button2: "satellite",
                        button3: "landform",
                        button4: "Label",
                        button5: "Current Location"
                    },
                    resultbox: {
                        box1:{title: "Inspection results", text1: "  Total Number",error1: "There is No Registered Vendor"}
                    }

                },
                vendors: {
                    table: {
                        title: "Status by Vendors",
                        colum1: "Company name",
                        colum2: "Code",
                        colum3: "Representative",
                        colum4: "Email",
                        colum5: "Phone number",
                        colum6: "Number of devices",
                        colum7: "Number of Kit sales",
                        colum8: "Number of kit used",
                        page: "rows per page",
                        error1: "No Data"
                    },
                    serchinputbox: {
                        box1: "Please enter vendors",
                        box2: "Please enter manager",
                        submit: "SEARCH",
                        boxLabel1: "All",
                        error1:"No Data"
                    }

                }
            },

            testresulttype:{
                label1:"negative",
                label2:"positive",
                label3:"inconclusive",
                label4:"invalid",
                label5:"cancel",
            },testresulttype2: ["Negative","Positive","Inconclusive","Invalid","Cancel"]
        }
}
