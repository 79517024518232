import React from "react";
// import "/node_modules/bootstrap/dist/css/bootstrap.css"
//import style from "./onedropCSArticleBoardWrite.module.css";
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
const Comments = ({ posts, loading,delComment,setarticleSeqSelected,setarticletypeSelected,login_info,totalPosts,currentPage,postsPerPage,setarticleindexSelected,articleSeqSelected,boardDetail,setloading,setreload,reload,insertComment }) => {
    return (
        <>
            {loading && <div> loading... </div>}
            <Container style={{width:'1000px',  position:"relative",'margin-top': '50px'}}>
                {
                    posts.map((list,index) =>
                         <Table bordered={true} style={list.priority == 2 ? "'padding-left': '48px'" :  "'padding-left': '0px'"}>
                        {/*<Table bordered={true} >*/}
                            <thead>
                            <tr>

                                <td rowSpan="2" width={"50px"} style={{textAlign:"center", "vertical-align": 'middle'}}>{totalPosts - (currentPage - 1) * postsPerPage - index}</td>
                                <td height={"30px"} width={"100px"} style={{textAlign:"center", "vertical-align": 'middle'}}>작성자</td>
                                <td width={"100px"} style={{textAlign:"center", "vertical-align": 'middle'}}>{list.writer}</td>
                                <td width={"100px"} style={{textAlign:"center", "vertical-align": 'middle'}}>날짜</td>
                                <td width={"250px"} style={{textAlign:"center", "vertical-align": 'middle'}}>{new Date(list.createdate).toLocaleDateString("ko-KR") +" "+ new Date(list.createdate).toLocaleTimeString()}</td>
                                <td width={"100px"} rowSpan="2" style={{textAlign:"center", "vertical-align": 'middle'}} onClick={() => {insertComment( list.seq, setarticletypeSelected,articleSeqSelected, login_info,setreload,reload,2);}}>답변 쓰기</td>
                                <td width={"100px"} rowSpan="2" style={{textAlign:"center", "vertical-align": 'middle'}} onClick={() => {delComment(list.commentseq, boardDetail[0].seq, setarticletypeSelected,articleSeqSelected,setreload,reload)}}>삭제</td>

                            </tr>
                            <tr>

                                <td  colSpan="5" width={"400px"} style={{textAlign:"center", "vertical-align": 'middle'}} height={"50px"}>{list.comment}</td>

                            </tr>
                            </thead>
                        </Table>
                    )
                }
            </Container>
        </>
    );
};
export default Comments;