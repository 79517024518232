
import {Avatar} from "@mui/material";

import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListIcon from '@mui/icons-material/List';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MuiEditIcon from '@mui/icons-material/Edit';
import MuiCheckIcon from '@mui/icons-material/Check';

import MuiFaceIcon from '@mui/icons-material/Face';

import FavoriteIcon from '@mui/icons-material/Favorite';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import CancelIcon from '@mui/icons-material/Cancel';

import BarChartIcon from '@mui/icons-material/BarChart';
import MapIcon from '@mui/icons-material/Map';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';


export const TaskEnabledIcon = (props) => <AlarmOnIcon {...props} />;
export const TaskDisabledIcon = (props) => <AlarmOffIcon {...props} />;
export const AddIconFilled = (props) => <AddCircleIcon {...props}  />; 
export const BackToListIcon = (props) => <MenuBookIcon {...props}  />; 
export const DoneByStepIcon = (props) => <CheckCircleIcon {...props}  />; 

export const CheckIcon = (props) => <MuiCheckIcon {...props}  />; 

export const DeleteIcon = (props) => <Avatar  {...props} sx={{ width: 32, height: 32 }}><DeleteForeverIcon  /></Avatar>; 
export const EditIcon = (props) => <Avatar  {...props} sx={{ width: 32, height: 32 }}><MuiEditIcon /></Avatar>;  //<Avatar sx={{ width: 40, height: 40 }}><EditIcon /></Avatar>

export const FaceIcon = (props) => <MuiFaceIcon style={{color:'#666'}}  {...props}  />; 

export const TestResultIconForChip = (idx) =>  {
    return {
        'negative' : FavoriteIcon,
        'positive' : ErrorIcon,
        'inconclusive' : HelpIcon,
        'invalid' : CancelIcon,
        'cancel' : null,
    }[idx];
};

export {BarChartIcon, MapIcon, PersonOutlineIcon};