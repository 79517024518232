import './clustercss.css'
import {TEST_RESULT_TYPE} from "__config/dashboardConfig";
import { TransverseLoading } from 'react-loadingg';

import React, {useEffect, useState, useCallback} from 'react'
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    InfoWindow,
    Circle,
    InfoBox,
    MarkerClusterer, GoogleMarkerClusterer,
} from '@react-google-maps/api';
import {DistributorContext, useContext} from "../__context_api/DistributorContext";
import {randomBetween} from "../__lib/aTools";
//import marker from "@react-google-maps/api/src/components/drawing/Marker";



let countClearNum = 0;
let totalcount =0;
export let stopOnClusteringEndYnParam = false;
export function setStopOnClusteringEndYnParam(boolean){
    stopOnClusteringEndYnParam = boolean;
}
const options = {
    streetViewControl:false
};
const containerStyle = {
    width: '100%',
    height: '664px'
};

let maxcount = 0;
let mincount = 999999999999999;
let diffCount = 0;
let markersClusterCount =0;
let markerClusterYnArray = [{"Yn": false,"Value":0},{"Yn": false,"Value":0},{"Yn": false,"Value":0},{"Yn": false,"Value":0},{"Yn": false,"Value":0},{"Yn": false,"Value":0}];
let countYN = [0,0,0,0,0,0];
function CALCULATOR(markers,numStyles) {

    const count = markers.length
    let text = count.toString();
    totalcount += markers.length;

    let indexcount = 0;

    if (count > (diffCount/6) * 5) {

        if(countYN[0] == 0){
            markerClusterYnArray[0]={"Yn":true,"Value":count}
        }
        countYN[0]++;
        indexcount = 1;
    } else if (count> (diffCount/6) * 4) {
        if(countYN[1] == 0) {
            markerClusterYnArray[1] = {"Yn": true, "Value": count}
        }
        countYN[1]++;
        indexcount = 2;
    } else if (count > (diffCount/6) * 3) {
        if(countYN[2]== 0) {
            markerClusterYnArray[2] = {"Yn": true, "Value": count}
        }
        countYN[2]++;
        indexcount = 3;
    } else if (count > (diffCount/6) * 2) {
        if(countYN[3] == 0) {
            markerClusterYnArray[3] = {"Yn": true, "Value": count}
        }
        countYN[3]++;
        indexcount = 4;
    } else if (count> (diffCount/6) * 1) {
        if(countYN[4] == 0) {
            markerClusterYnArray[4] = {"Yn": true, "Value": count}
        }
        countYN[4]++;
        indexcount = 5;
    } else {
        if(countYN[5] == 0) {
            markerClusterYnArray[5] = {"Yn": true, "Value": count}
        }
        countYN[5]++;
        indexcount = 6;
    }

    if (markersClusterCount <= 6) {
        if (markersClusterCount > 1 && markersClusterCount < 6) {

            if (markerClusterYnArray[indexcount-1].Yn == true && countYN[indexcount-1] > 1 ) {
                if(markerClusterYnArray[indexcount-1].Value < count){
                    if(indexcount  < 6){
                        indexcount =indexcount - countYN[indexcount-1] + 1;
                    }
                }else if(markerClusterYnArray[indexcount-1].Value > count){
                    if(indexcount >0) {
                        indexcount =indexcount + countYN[indexcount-1] - 1;
                    }
                }else{
                }
            }
        }
    }
    let  index = indexcount;
    return {
        text: count.toString(),
        index,
        title: '',
    }
}

// ----------- dot marker

// const MarkerDefault = (props,onMarkerClick,row) => {

//     return (
//         <Marker
//             {...props} position={{lat: props.row.lat, lng: props.row.lng}} onClick={(e) => {props.onMarkerClick(e, props.row);}}
//         />
//     );
// };
// const MarkerDefault = (props,onMarkerClick,row) => {

//     return (
//         <Marker
//             {...props} position={{lat: props.row.lat, lng: props.row.lng}} onClick={(e) => {props.onMarkerClick(e, props.row);}}
//         />
//     );
// };
const MakerArray = ({dot_on_map, onDotMarkerClick}) => {

    useEffect(() => {    return () => null;}, [])

    return (
        <>
            {
                dot_on_map.map((row, index) => {


                    return (

                         <Marker   key={index} id={"markerdiv" + index} setZIndex={10000}  visible={true} position={{lat: row.lat, lng: row.lng}} row={row} onClick={(e) => {onDotMarkerClick(e, row,Marker);}}/>



                    )
                })
            }
        </>
    )
};


function ClusteringEnd(clusterer) {
    totalcount = 0
    maxcount = 0;
    mincount = 999999999999999;
    countYN = [0,0,0,0,0,0];
    countClearNum = 1;

    markersClusterCount = clusterer.clusters.length;

    for (let i = 0; i < markersClusterCount; i++) {
        maxcount = Math.max(maxcount, clusterer.clusters[i].markers.length);
        mincount = Math.min(mincount, clusterer.clusters[i].markers.length);
    }
    diffCount = maxcount - mincount;

}

let clusteroptions = {
    calculator: CALCULATOR,
    clusterClass: "cluster",
    styles: [
        {
            width: "150",
            height: "150",
            textColor:'white', textSize:"20", fontWeight:'bold'
        },
        {
            width: "100",
            height: "100",
            textColor:'white', textSize:"20", fontWeight:'bold'
        },
        {
            width: "75",
            height: "75",
            textColor:'white', textSize:"20", fontWeight:'bold'
        },
        {
            width: "60",
            height: "60",
            textColor:'white', textSize:"20", fontWeight:'bold'
        },
        {
            width: "40",
            height: "40",
            textColor:'white', textSize:"20", fontWeight:'bold'
        },
        {
            width: "20",
            height: "20",
            textColor:'white', textSize:"20", fontWeight:'bold'
        }
    ],
    zoomOnClick: false,
    gridSize: 150,
    maxZoom: 10

}


const list_by_vendor = (by_vendor) => {
    if (!by_vendor || Object.keys(by_vendor).length===0) {
        return [];
    };
    return Object.entries(by_vendor).map( ([kk,vv]) => (
        {
            idx : kk,
            label: vv.name,
            name : vv.name,
            color : vv.color.replace('0x','#'),
            //sell : vv.use_count + randomBetween(0, vv.use_count),
            sell : 0,
            use : vv.use_count,
            count: 0
        }
    ));
};

export function GoogleMapByRegionalInfo({
                                            dots_array,
                                            stats_array,
                                            onClickMarker,
                                            passCurrentMapState,
                                            is_analysis_loading,

                                            clusterer,
                                            center,
                                            searchOption,
                                            TEST_RESULT_TYPE2
                                        }) {


    ////////******


    const { login_info}  = useContext(DistributorContext);
    const { location_analysis}  = useContext(DistributorContext);
    const by_vendor = location_analysis.vendor;// null 일 수 잇음// 리스트가 있음  //벤더가 진짜 0개일 때도 처리
    let dateselect = searchOption;
    const vendorinfoList = list_by_vendor(by_vendor);
    let vendorinfo = vendorinfoList;

    let TEST_RESULT_TYPE = TEST_RESULT_TYPE2;
    //let markers = marker;


    if(vendorinfoList.length == 0){
        vendorinfo =  undefined;
    }

    //const sizeOfCircleMarker = (value, max_value) => (value / max_value) * 25 + 10;
    const analysisMarkersWith = (onAnalysisMarkerClick) => {
        return ({data_on_map, current_zoom, min_zoom}) => {


            return null
        };
    }

    const InfoBoxText = ({target}) => {

    if(target) {
            return (
                <div
                    style={{
                        backgroundColor: '#fff',
                        opacity: 0.95,
                        borderRadius: '5px',
                        padding: '15px 5px 15px 5px',
                        width: '105px',
                        height: '58px'
                    }}>

                    <div style={{fontSize: 12, color: '#444', padding: '0 0 0 0'}}>
                        <div> {target.info.complete_date.slice(0, 10)}</div>
                        <div style={{
                            fontSize: '12px',
                            color: 'blue'
                        }}> {(target.info.vendor_name == undefined || target.info.vendor_name == "invalid") ? 'Vendor Unknown'  : target.info.vendor_name.slice(0, 16) } </div>
                        <div
                            style={{marginBottom: '2px'}}> {target.info.kit_lot ? target.info.kit_lot.slice(0, 18) : 'kit_lot Unkown'} </div>
                        <div style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: Object.values(TEST_RESULT_TYPE)[target.info.result].color
                        }}> {Object.values(TEST_RESULT_TYPE)[target.info.result].label} </div>
                    </div>
                </div>
            );
        }else{
        return (
                <div
                    style={{
                        backgroundColor: '#fff',
                        opacity: 0.95,
                        borderRadius: '5px',
                        padding: '15px 5px 15px 5px',
                        width: '105px',
                        height: '58px'
                    }}>

                    <div style={{fontSize: 12, color: '#444', padding: '0 0 0 0'}}>
                        <div> {}</div>
                        <div style={{
                            fontSize: '12px',
                            color: 'blue'
                        }}> {'No Data'} </div>
                        <div
                            style={{marginBottom: '2px'}}> {} </div>
                        <div style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: Object.values(TEST_RESULT_TYPE)[target.info.result].color
                        }}> {Object.values(TEST_RESULT_TYPE)[target.info.result].label} </div>
                    </div>
                </div>
            );
        }
    };

    const InfoBoxByDot = ({target}) => {


        const infobox_options = {
            closeBoxURL: '',
            pixelOffset: new window.google.maps.Size(-60, -135),
            enableEventPropagation: true,
            disableAutoPan: true,
        };


        return (

            <InfoBox
                options={infobox_options}
                position={{lat: target.lat, lng: target.lng}}>
                <div style={{'overflow': 'hidden',}}
                     >
                    <InfoBoxText target={target}/>
                </div>
            </InfoBox>
        )

    };



    useEffect(() => { return () => null;

    }, []);

    // const [min_zoom, max_zoom] = [Math.min(...Object.keys(stats_array)), Math.max(...Object.keys(stats_array)) -1];
    const [min_zoom, max_zoom] = [8,11];
    const DEFAULT_ZOOM = isFinite(min_zoom) ? min_zoom : 8;
    const [currentZoom, setCurrentZoom] = useState(DEFAULT_ZOOM);

    const analysisDataByCurentZoom = (current_zoom) => {
        return stats_array[(current_zoom < min_zoom) ? min_zoom : (current_zoom > max_zoom) ? max_zoom : current_zoom];    //현재보다 민줌이 크면 민줌 민줌이 작으면 맥스줌하고 비교, 비교후 커런줌이 크면 맥스줌 아니면 커런줌ㅞㅡ
    };

    const centerMap = center;
    const [map, setMap] = useState(null);
    const {isLoaded, loadError} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCtOLBTecD5KvrLQDDU5F8CWynQ6Hue9gw" // ,
        // ...otherOptions
    });

    // ---------- dot click handle -----------//
    const [dotMarkerEventState, setDotMarkerEventState] = useState({
        isOpen: false,
        lat: null,
        lng: null,
        info: '',
    });

    const reducerDotMarkerAction = (state, action,marker) => {

        const is_infobox_open = (is_open, state, target) => {

            // if (!state.isOpen) {
            //     return true;
            // };

            if (state.isOpen && state.lat === target.lat && state.lng === target.lng) {
                return false;
            } else {
                return true;
            };
        };

        switch (action.type) {
            case 'DOT_MARKET_CLICK' :

                setDotMarkerEventState(
                    {
                        ...state,
                        isOpen: is_infobox_open(state.isOpen, state, action.target),
                        lat: action.target.lat,
                        lng: action.target.lng,
                        info: action.target.info, //'setDotMarkerEventState',
                        // marker:markers.key
                    });
                break;
            case 'CLOSE_BOX' :

                setDotMarkerEventState(
                    {
                        ...state,
                        isOpen: false,
                        lat: null,
                        lng: null,
                        info: null, //action.target.info, //'setDotMarkerEventState',  //마커 클릭 안된느 이유??
                    });
                break;

            default :

        }
        ;
    };

    const [displayLocation, setDisplayLocation] = useState(false);

    const onDotMarkerClick = (e, row) => {


        reducerDotMarkerAction(dotMarkerEventState, {type: 'DOT_MARKET_CLICK', target: row});
    };

    const onAnalysisMarkerClick = (cluster,current_zoom, selected) => {

        let getMarkers = cluster.getMarkers();
        let totalTestCount = 0;
        let nagativeResult = 0;
        let positiveResult = 0;
        let inconclusiveResult = 0;
        let invalidResult = 0;
        let cancelResult = 0 ;
        let otherResult = 0;

        let dateStart = new Date('2020/01/01');
        let dateEnd = new Date('2022/12/31');
        let overArray = [];


        if(!(dateselect.date_to == "" || dateselect.date_to == null || dateselect.date_to == undefined)){
            dateEnd = new Date(dateselect.date_to);
        }
        if(!(dateselect.date_from == "" || dateselect.date_from == null || dateselect.date_from == undefined)){
            dateStart = new Date(dateselect.date_from);
        }

        dots_array.map((row,index)  => {
            for(let i = 0; i < getMarkers.length ; i++){

                const date = new Date(row.date);

                if(Number(getMarkers[i].position.lng().toFixed(6)) === row.lng
                    && Number(getMarkers[i].position.lat().toFixed(6)) === row.lat
                    && isNaN(row.lat) === false
                    && isNaN(row.lng) === false
                    && date >= dateStart
                    && date <= dateEnd   )
                {
                    overArray.push(row);
                }
            }
            return null;
        })
        let set = new Set(overArray);

        let uniqueArr = [...set];

        for(let i = 0; i < uniqueArr.length ; i++){

            if(uniqueArr[i].result == 0){
                nagativeResult++;
            }else if(uniqueArr[i].result == 1){
                positiveResult++;
            }else if(uniqueArr[i].result == 2){
                inconclusiveResult++;
            }else if(uniqueArr[i].result == 3){
                invalidResult++;
            }else if(uniqueArr[i].result == 4){
                cancelResult++;
            }else if(uniqueArr[i].result == null ){
                otherResult++;
            }


            if(vendorinfoList.length == 0){

                vendorinfo = undefined;

            }else{

                for(let j = 0 ; j < vendorinfo.length ; j++){

                    if(uniqueArr[i].vendor === vendorinfo[j].idx){
                        vendorinfo[j].count += 1;
                    }

                }


            }
            totalTestCount++;
        }

        const selectedCustom = {
            count:getMarkers.length ,
            current_zoom: current_zoom,
            lat: cluster.center.lat(),
            lng: cluster.center.lng(),
            max_count: totalTestCount,
            negative : nagativeResult,
            positive : positiveResult,
            inconclusive : inconclusiveResult,
            //max_count : Math.max(...by_zoom.map(row => row.count)),
            invalid :invalidResult,
            //r : undefined , //row.count/5,
            otherResultCount : otherResult,
            cancel: cancelResult,
            vendorinfo:vendorinfo
        }

        onClickMarker(selectedCustom);
    };

    const AnalysisMarkers = useCallback(analysisMarkersWith(onAnalysisMarkerClick), []);
    const MakersLocation = useCallback(MakerArray, [displayLocation]);

    const handleZoomChanged = (ee) => {
        if (isLoaded && ee) {
            setCurrentZoom(
                (isFinite(ee.zoom)) ? ee.zoom : currentZoom
            );
            reducerDotMarkerAction(dotMarkerEventState, {type: 'CLOSE_BOX',});

        } else {

        }
        ;
    };

    return (
        (isLoaded && stats_array && Object.keys(stats_array) !== 0)
            ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={8}
                    options={options}
                    onLoad={(map, maps) => {
                        setMap(map);
                    }}
                    onZoomChanged={() => handleZoomChanged(map)}
                    onUnmount={(map, maps) => {
                        setMap(map);
                    }}
                    onDrag={(map, maps) => {
                        setMap(map);
                    }}

                >

                    <MarkerClusterer
                        options={clusteroptions}

                        onClusteringEnd={(clusterer) =>ClusteringEnd(clusterer)}
                        onClick={(e) => onAnalysisMarkerClick(e,8 )}
                    >
                        {(clusterer) =>
                            dots_array.map((location, index) => (
                                    <Marker key={index} position={location} index={index}
                                            option={{noClustererRedraw:true}} clusterer={clusterer} onClick={(e) => onDotMarkerClick(e, location)}/>

                                )
                            )
                        }

                    </MarkerClusterer>
                    {
                        (displayLocation)
                            ? <MakersLocation onDotMarkerClick={onDotMarkerClick} dot_on_map={dots_array}/>
                            : (currentZoom >= min_zoom) ?
                                <AnalysisMarkers current_zoom={currentZoom} min_zoom={min_zoom}/> : ''
                    }
                    {dotMarkerEventState.isOpen && <InfoBoxByDot target={dotMarkerEventState}/>}

                </GoogleMap>
            )
            : (
                <div>
                    <div style={{position: 'relative'}} align='center'>
                        <TransverseLoading
                            color='blue' size='large'
                            style={{
                                height: '80px',
                            }}/>
                    </div>
                    <div style={{paddingTop: '15px'}}><h3>{'Map loading...'}</h3></div>
                </div>
            )
    )
};

const LocationOnMap = React.memo(GoogleMapByRegionalInfo);
export default LocationOnMap ;