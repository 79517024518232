
//import {useRequestAPI} from "__api/useRequestAPI"; 
import {useRequestAPI2} from "__api/useRequestAPI2";

export function useLocationAnalysis (
    params,
    token='access_token=dfa126bbb62c44ddb531ba1d0fd0f4bf',
    host= process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr',
    //host='',
    //  host='homekit.1drop.co.kr',
    _render_key='',
    ){

    const parser = ([is_loading, rtn]) => {     

        return [is_loading, rtn ];
    };

    const render_key = _render_key || `${params.distributor_code}${params.start_time}${params.end_time}${params.vendor_code_name}${params.periodical_type}` ;
    //const URL = 'https://testsrv.1drop.co.kr/homekit/admin/api/location/analysis';
    //const URL = process.env.REACT_APP_DISTRIBUTOR_ANALYSIS + '/homekit/admin/api/location/analysis';
    let URL = process.env.REACT_APP_DISTRIBUTOR_ANALYSIS || '/homekit/admin/api/location/analysis';

    // const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/location/analysis';
    const headers = {
        'Access-Control-Request-Method':"post",
        'Access-Control-Request-Headers':"*"
    };
    return parser(
        useRequestAPI2( 
            URL, // url
            params, // params
            headers,
            render_key
            ));
};



export function useLocationMapAnalysis  (
    params,
    token='access_token=dfa126bbb62c44ddb531ba1d0fd0f4bf', 
    //host='testsrv.1drop.co.kr',
    host= process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr',
    //  host='homekit.1drop.co.kr',
    _render_key='',
    ) {

    const parser = ([is_loading, rtn]) => {        
        return [is_loading, rtn ];
    };

    const render_key = _render_key || `${params.distributor_code}${params.start_time}${params.end_time}${params.vendor_code_name}` ;
    //const URL = 'https://testsrv.1drop.co.kr/homekit/admin/api/location/map_analysis';
    let URL = process.env.REACT_APP_DISTRIBUTOR_MAP_ANALYSIS || '/homekit/admin/api/location/map_analysis';
    //const URL = process.env.REACT_APP_DISTRIBUTOR_MAP_ANALYSIS + '/homekit/admin/api/location/map_analysis';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/location/map_analysis';

    const headers = {
        'Access-Control-Request-Method':"post",
        'Access-Control-Request-Headers':"*"
    };
    return parser(
        useRequestAPI2(
            URL, // url
            params, // params
            headers,
            render_key
            ));
};



export function useLocationMapAnalysisDetail  (
    params,
    token='access_token=dfa126bbb62c44ddb531ba1d0fd0f4bf',
    host= process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr',

    // host='homekit.1drop.co.kr',
    _render_key = ''
    )  {

    const parser = ([is_loading, rtn]) => {
        return [is_loading, rtn ];
    };

    const render_key = _render_key || `${params.grid_center_longitude}${params.grid_center_latitude}${params.zoom_level}${params.distributor_code}${params.start_time}${params.end_time}${params.vendor_code_name}` ;
    //const URL = 'https://testsrv.1drop.co.kr/homekit/admin/api/location/map_analysis_detail';
    let URL = process.env.REACT_APP_DISTRIBUTOR_MAP_ANALYSIS_DETAIL || '/homekit/admin/api/location/map_analysis_detail';

    //const URL = process.env.REACT_APP_DISTRIBUTOR_MAP_ANALYSIS_DETAIL + '/homekit/admin/api/location/map_analysis_detail';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/location/map_analysis_detail';
    const headers = {
        //'host': host,
        //'Cookie': token,
    };
    return parser(
        useRequestAPI2(
            URL, // url
            params, // params
            headers,
            render_key
            ));
};

export function useLocationMapDot  (params,
    token='access_token=dfa126bbb62c44ddb531ba1d0fd0f4bf',

    host= process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr',

    ) {

    const parser = ([is_loading, rtn]) => {        
        return [is_loading, rtn ];
    };
    let URL = process.env.REACT_APP_DISTRIBUTOR_MAP_DOT || '/homekit/admin/api/location/map_dot';

    //const URL = 'https://testsrv.1drop.co.kr/homekit/admin/api/location/map_dot';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/location/map_dot';
    const headers = {
        'Access-Control-Request-Method':"post",
        'Access-Control-Request-Headers':"*"
    };

    return parser(
        useRequestAPI2(
            URL, // url
            params, // params
            headers
            ));
};


export function useLocationVender (
    token='access_token=dfa126bbb62c44ddb531ba1d0fd0f4bf',
    host= process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr',
   //host='testsrv.1drop.co.kr',
    //   host='homekit.1drop.co.kr',
   params
    )  {

    const parser = ([is_loading, rtn]) => {        
        return [is_loading, rtn ];
    };
    let URL = process.env.REACT_APP_DISTRIBUTOR_VENDOR || '/homekit/admin/api/location/vendor';

    //const URL = 'https://testsrv.1drop.co.kr/homekit/admin/api/location/vendor';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/location/vendor';
    const headers = {
        'Access-Control-Request-Method':"post",
        'Access-Control-Request-Headers':"*"
    };
    return parser(
        useRequestAPI2(
            URL, // url
            params, // params
            headers
            ));
};