//import React, {useEffect} from 'react';
//import PropTypes from 'prop-types';

import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,TableSortLabel   } from '@mui/material';
//import { Box, Toolbar, Typography,Paper, Checkbox, IconButton,  Tooltip, FormControlLabel, Switch    } from '@mui/material';

import styled from '@emotion/styled';



export const TableCellDense2 = styled(TableCell)`
  padding: 0px 10px;
  min-width: 30;
`;

export const TableCellDense3 = styled(TableCell)`
  padding: 0px 10px;
  min-width: 30;
  text-align: center;
`;

export const TableRowDense2 = styled(TableRow)`
  height: 28px;
`;