import {useContext, useEffect} from "react";
import axios from "axios";
import React from 'react';
import {ArticleContext} from "../article/onedropCSArticleBoardMain";
import {SiteContext} from "../__context_api/SiteContext";
//import {useHistory} from "react-router-dom";





 export default  async function Emailsite() {
     //let history = useHistory();
     // eslint-disable-next-line no-restricted-globals
     const urlParams = new URL(location.href).searchParams;
     //const {accesstoken} = useContext(SiteContext);
     let accesstoken;
     const code = urlParams.get('code');

     // let url = "https://oauth2.googleapis.com/token";

     const headers = {};
     let result;


     useEffect(() => {

         // result= getOuth(code);


     }, []);

     result = await getOuth(code);

     if(result.status ===200){

         accesstoken = result.data.access_token;
     }
     let accesstokenObj = {"accesstoken":accesstoken
     }

     window.localStorage.setItem("onedropemail", JSON.stringify(accesstokenObj));
     return null;
 }




const getOuth = async(code) => {
    let url = "https://oauth2.googleapis.com/token";
     const params = {
         "client_id":"588490015034-p6l1agitdf7r8jd113e3jlc2srvq5rfl.apps.googleusercontent.com",
         "client_secret":"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",
         "code":code,
         "grant_type":"authorization_code",
         "redirect_uri":"https://localhost:3000/emailsite",

     };
     const headers = {

     };
    let result
    try {


        result = await axios.post(url, params, {headers})



    }catch(err){

    }


    return result;
 }