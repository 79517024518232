import React from 'react';
//import Chip from '@mui/material/Chip';
import {Chip} from "__ui_components/UIComponents";
//import {Paper, Container, Typography, List, ListItem} from '@mui/material';


export default function SingleChip ({ label, color, icon }) { 
    return (
        <>
            <div style={{width:80, textAlign: "left",}}>
            <Chip 
                style={{ color:'#000', backgroundColor:color, height:25, paddingLeft:0, paddingRight:0}} 
                icon={icon} 
                label={label} 
                label = {< div style={{marginLeft:'-3px'}}>{label}</div>}
                />
            </div>           
        </>
    )
};