
import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";

export default function SelectItem ({input_label, value, item_to_select, handleChange, is_set_all=false, textLangSitesearhinput, testresulttype,testreusltlang,fromObj}) {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
          width: 250,
          backgroundColor : '#fff',
        },
      },
    };
    let testreusltlangoriginlist;
    let testreusltlangObj;

    if(fromObj == "testcenter"){

        testreusltlangObj = testreusltlang.testresult;
        testreusltlangoriginlist = testreusltlang.testresultorigin;

        return (
            <FormControl fullWidth size="small">
                <InputLabel id="select-item">{input_label}</InputLabel>
                <Select
                    labelId="select-item"
                    id="select-item"
                    //xxdefaultValue={value}
                    value={value == "" ? textLangSitesearhinput.boxLabel1 ? textLangSitesearhinput.boxLabel1 : undefined : value}
                    label={input_label}
                    onChange={(e) => handleChange( ( e.target.value == undefined || e.target.value == "")  ? ""  : e.target.value )}
                    sx = {{backgroundColor: '#fff'}}
                    MenuProps={MenuProps}
                >
                    { (is_set_all) ? <MenuItem key={''} value={textLangSitesearhinput.boxLabel1}>{textLangSitesearhinput.boxLabel1}</MenuItem> : ''}
                    {
                        testreusltlangObj.map(
                            (item, index) => <MenuItem key={index} value={testreusltlangoriginlist[index+1] }>{item}</MenuItem>
                        )
                    }

                </Select>
            </FormControl>
        );


    }else {
        return (
            <FormControl fullWidth size="small">
                <InputLabel id="select-item">{input_label}</InputLabel>
                <Select
                    labelId="select-item"
                    id="select-item"
                    //xxdefaultValue={value}
                    value={value == "" ? textLangSitesearhinput.boxLabel1 ? textLangSitesearhinput.boxLabel1 : undefined : value}
                    label={input_label}
                    onChange={(e) => handleChange((e.target.value == undefined || e.target.value == "") ? "" : e.target.value)}
                    sx={{backgroundColor: '#fff'}}
                    MenuProps={MenuProps}
                >
                    {(is_set_all) ? <MenuItem key={''}
                                              value={textLangSitesearhinput.boxLabel1}>{textLangSitesearhinput.boxLabel1}</MenuItem> : ''}
                    {
                        item_to_select.map(
                            (item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>
                        )
                    }

                </Select>
            </FormControl>
        );
    }
};