import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";
import Table from 'react-bootstrap/Table';
import SelectItem from "_components_public/SelectItem";
import {
    useGetBoardList,
    useFieldTestCenter,
    useGetBoardDetail,
    useGetBoardDetailForModify, useGetBoardReplyList, useGetBoardCommentList
} from "../__api/requestOneDropData";
import axios from "axios";

import {ArticleContext} from "./onedropCSArticleBoardMain";
import {SiteContext} from "../__context_api/SiteContext";
export default function ComponentArticleModify(seq) {
    const { articletypeSelected, setarticletypeSelected, articleSeqSelected, setarticleSeqSelected,articleindexSelected } = useContext(ArticleContext);
    const { login_info, setlogin_info } = useContext(SiteContext);

    const [is_loading, Boardmodify] = useGetBoardDetailForModify(articleSeqSelected,login_info);
   function isEmptyObj(obj)  {
        if(typeof obj === Object){
            if(obj.constructor === Object
                && Object.keys(obj).length === 0)  {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        if(isEmptyObj(Boardmodify) != true){
            if(!is_loading){setObjectModify();

            }
        }; return () => null;},[Boardmodify ]);

    let boardDetail ;

    let boardModifiy;

    let boardModifiyObj;

    function setObjectModify(){

        const BoardmodifyOBj = Boardmodify.article;
        if( !is_loading){

            if(BoardmodifyOBj.length > 0 ){

            }
        }

    }

    function setarticleSeqSelectedFun(setarticleSeqSelected,seq){
        setarticleSeqSelected(seq);
        return null;
    }


    boardModifiy = Boardmodify.article;

    if (boardModifiy != null) {

        return (

            <>
                {(!is_loading) ?
                    <Table bordered >
                        <thead>
                        <tr>
                            <th width={'50px'} colSpan="2">{login_info.site_key == 'onedrop' ? boardModifiy[0].writer +"-"+ boardModifiy[0].seq  : articleindexSelected}</th>
                            <th width={'80px'}>날짜</th>
                            <th width={'200px'}>{ new Date(boardModifiy[0].createdate).toLocaleDateString("ko-KR") +" "+ new Date(boardModifiy[0].createdate).toLocaleTimeString()}</th>
                            <th width={'80px'}>작성자</th>
                            <th width={'100px'}>{boardModifiy[0].writer}</th>
                            <th width={'80px'}>수정날짜</th>
                            <th width={'200px'}>{new Date(boardModifiy[0].modifieddate).toLocaleDateString("ko-KR") +" "+ new Date(boardModifiy[0].modifieddate).toLocaleTimeString()}</th>
                        </tr>
                        <tr style={{height : '35px'}}>
                            <th width={'100px'} style={{height : '35px'}}>제목</th>
                            <th colSpan="7" style={{height : '35px'}} width={'300px'}><textarea style={{'width':'100%', "height":'35px', resize: "none"}}  id={"modifytitle"}>{boardModifiy[0].title}</textarea></th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td >내용</td>
                            <td  colSpan="9" height={'300px'} width={'700px'}><textarea style={{'width':'100%', "height":'100%', resize: "none"}} id={"modifycontent"}>{boardModifiy[0].content}</textarea></td>
                        </tr>
                        </tbody>
                    </Table>
             : ''}
                <input type = "button" value = "리스트로" onClick={() =>{setarticletypeSelected('OnedropCSArticleBoard')}}></input>
                <input type = "button" value = "수정" onClick={() => {modifyFun(boardModifiy,login_info,setarticletypeSelected);}} ></input>

            </>

        )
    }else {return null;}
}
function modifyFun(boardModifiy,login_info,setarticletypeSelected){
    let articleTitle = document.getElementById('modifytitle').value;
    let articleContent = document.getElementById('modifycontent').value;

    if (articleTitle == "") {
        alert("제목 창에 내용을 입력해주세요");
        return false;
    }
    if (articleTitle.length > 200) {
        alert("제목 창에 내용이 너무 깁니다.");
        return false;
    }

    if (articleContent == "") {
        alert("내용 창에 내용을 입력해주세요");
        return false;
    }
    if (articleContent.length > 1000) {
        alert("내용 입력 창에 내용이 너무 깁니다.");
        return false;
    }

    let boardModifiyObj = {
        seq:boardModifiy[0].seq,
        title:boardModifiy[0].title,
        content:boardModifiy[0].content,
        writer:boardModifiy[0].writer,
        createdate:boardModifiy[0].createdate,
        modifieddate:boardModifiy[0].modifieddate,
        modifier:login_info.site_key
    };

    boardModifiyObj.title = articleTitle;
    boardModifiyObj.content =articleContent;

    setarticletypeSelected('OnedropCsArticleBoardDetail');

}

async function sendModifiedArticle(boardModifiyObj){

    const params = {
        'seq':boardModifiyObj.seq,
        'title' : boardModifiyObj.title,
        'content' : boardModifiyObj.content,
        'writer' : boardModifiyObj.writer,
        'modifier':boardModifiyObj.modifier,
    }
    const headers ={
        'Content-Type': 'application/json'
    }

    const URL = process.env.REACT_APP_SITEDATA_ARTICLE_WRITE || '/homekit/admin/article/write';
    let responseArticleData = null;
    try{
        responseArticleData = await axios.post(
            URL, params, { withCredentials: true});

        responseArticleData = responseArticleData.data;

        if(responseArticleData.error != '' || responseArticleData.error != null){

        }
    }catch(err){

    }


    return responseArticleData;

}