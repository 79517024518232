import React, {Component, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import deleteImg from'__images/delete_button.png';

import { alpha } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,TableSortLabel   } from '@mui/material';
import { Box, Toolbar, Typography,Paper, Checkbox, IconButton,  Tooltip, FormControlLabel, Switch    } from '@mui/material';

//import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import FilterListIcon from '@mui/icons-material/FilterList';

import { visuallyHidden } from '@mui/utils';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/styles';

import {TableRowDense2, TableCellDense2, TableCellDense3} from "_components_table/TableElement";
import TableTestCenter, {settestcenter_data, settestcenter_dataforRow} from "../_components_table/TableTestCenter";
import EndUser03TestCenter, {test_result_mapFiltered} from "../containers/EndUser03TestCenter";
import './TableWithSortAndAction.css'
import axios from "axios";
import {requestDeleteTableRowForMemberOfClinomic,requestDeleteTableRowForTestCenter} from "../__api/requestDeleteTableRow";
import moment from "moment/moment";


let YnParam = false;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { headerCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRowDense2 >
        <TableCellDense2 padding="checkbox" >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all company',
            }}
          />
        </TableCellDense2>
        {headerCells.map((headCell) => (
          <TableCellDense2
            key={headCell.idx}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.idx ? order : false}            
          >
            <TableSortLabel
              active={orderBy === headCell.idx}
              direction={orderBy === headCell.idx ? order : 'asc'}
              onClick={createSortHandler(headCell.idx)}
            >
              {headCell.label}
              {orderBy === headCell.idx ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCellDense2>
        ))}
      </TableRowDense2>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headerCells : PropTypes.array.isRequired,
};

const FilterListDate = (props,FilterListDivYnParam,filterDate) => {
    //const filterInputDate = ["전체", "하루", "일주일", "한달"];
    const filterInputDate = ["전체", "하루", "일주일", "한달"];
    const propsParam = props.props.setfilterDate;



    const [isLoaded,setisLoaded] = useState(false);
    useEffect((event) => {
        if (isLoaded) { // DOM이 로드된 상태이면

        }else{
            setisLoaded(true);

        };
    },[isLoaded]);


    return (
        <>
            <div  className={"datetimediv"} style={{float:"left"}}><div style={{float:"left", padding: "0 0 0 10px"}}>날짜 시간</div>
                <div className="divcheckbox" style={{float:"left", padding: "0 0 0 0px"}} >
                    {filterInputDate.map((issue, index) => (
                        <IssueDate keyParam={index} key={index} value={issue} filterDate={props.props.filterDate} isLoaded={isLoaded}setfilterDate={propsParam} FilterListDivYnParam={props.props.FilterListDivYnParam}></IssueDate>
                    ))}
                </div>

            </div >

            </>
    );
};
const FilterListNotice = (props,filterNotice) => {
    const filterInputNotice = ["전체", "O", "X"] ;
    const [isLoadedNotice,setisLoadedNotice] = useState(false);
    const propsParam = props.props.setfilterNotice;
    useEffect(() => {
        if (isLoadedNotice) { // DOM이 로드된 상태이면

        }else{
            setisLoadedNotice(true);

        };
    },[isLoadedNotice]);

    return (
        <>
            <div className={"noticediv"} style={{float:"left"}}><div style={{float:"left" , padding: "0 0 0 10px"}}>통보</div><br/>
                <div   className="divcheckbox" style={{float:"left" , padding: "0 0 0 0px"}}  >
                    {filterInputNotice.map((issue, index) => (
                        <IssueNotice keyParam={index} key={index} value={issue} filterNotice={ props.props.filterNotice} isLoadedNotice={isLoadedNotice} setfilterNotice={propsParam} value={issue} props={props}>

                        </IssueNotice>
                    ))}
                </div>
            </div>
        </>
    );
};

const FilterListPosition = ({props, position_to_select, textLangtablefileter}) => {
    const [isOpenPParam,setisOpenPParam] = useState(false);
    const selectBoxPosition = ["전체",...position_to_select];
    const [isLoadedPosition,setisLoadedPosition] = useState(false);



    const [selectFilterPositionToStringParam, setselectFilterPositionToStringParam] = useState(props.filterPosition );
    useEffect(() => {
        if (isLoadedPosition) { // DOM이 로드된 상태이면

        }else{
            setisLoadedPosition(true);

        };
    },[isLoadedPosition,selectFilterPositionToStringParam]);



    const SelectBoxPositionFun = () => selectBoxPosition.map((item, index) => {
        return <option key={index} selected={selectFilterPositionToStringParam == item} value={item}>{item} </option>
    });

    return (
                <>
                    <div className={"datetimediv"} style={{float:"left"}}><div style={{float:"left", padding: "0 0 0 10px"}}
                                                                               onClick={() => setisOpenPParam(!isOpenPParam) } >직급</div>
                    </div>

                    {(isOpenPParam) ?
                    <select name="selectPostion" id="selectPostion"  onChange={(e) => {filterOnClickPosition(e); setselectFilterPositionToStringParam(e.target.value);}} >
                    <SelectBoxPositionFun/>
                    </select>: ''}
                </>
            );
};

const FilterListRecentTest = (props) => {
    const [isOpenRTParam, setisOpenRTParam] = useState(false);
    const selectBoxPosition = [props.textLangtablefileter];

    const [isLoadedRecentTestResult,setisLoadedRecentTestResult] = useState(false);
    const [selectFilterRecentTestResultParam, setselectFilterRecentTestResultParam] = useState(props.filterRecentTestResult );
    useEffect(() => {
        if (isLoadedRecentTestResult) { // DOM이 로드된 상태이면

        }else{
            setisLoadedRecentTestResult(true);

        };
    },[isLoadedRecentTestResult,selectFilterRecentTestResultParam]);


    const SelectBoxRecentTestFun = () => selectBoxPosition.map((item, index) => {
        return <option key={index} value={item} selected={selectFilterRecentTestResultParam == item}>{item}</option>
    });

    return (
                <>
                    <div className={"datetimediv"} style={{float:"left"}}><div style={{float:"left", padding: "0 0 0 10px"}} onClick={() => setisOpenRTParam(!isOpenRTParam)}>최근 테스트</div>
                    </div>

                    {(isOpenRTParam) ?
                        <select name="selectRecentTest" id="selectRecentTest" onChange={(e) => {filterOnClickRecentTest(e); setselectFilterRecentTestResultParam(e.target.value)}} >
                            <SelectBoxRecentTestFun/>
                        </select> : ''}
                </>
            );
}

const FilterListGender = (props) => {
    const [isOpenGParam,setisOpenGParam] = useState(false);
    const selectBoxPosition = [props.textLangtablefileter];
    const [isLoadedGender,setisLoadedGender] = useState(false);
    const [selectFilterGenderParam, setselectFilterGenderParam] = useState(props.filterGender );
    useEffect(() => {
        if (isLoadedGender) { // DOM이 로드된 상태이면

        }else{
            setisLoadedGender(true);

        };
    },[isLoadedGender,selectFilterGenderParam]);
    const SelectBoxGFun = (SelectBoxPosition) => selectBoxPosition.map((item, index) => {return <option key={index} selected={selectFilterGenderParam == item} value={item}>{item}</option>});

    return (
                <>
                    <div className={"datetimediv"} style={{float:"left"}}><div style={{float:"left", padding: "0 0 0 10px"}} onClick={() => setisOpenGParam(!isOpenGParam)}>성별</div>
                    </div>

                    {(isOpenGParam) ?
                        <select name="selectgender" id="selectgender" onChange={(e) => {filterOnClickGender(e); setselectFilterGenderParam(e.target.value);}} >
                            <SelectBoxGFun/>
                        </select>: ''}
                </>
            );
}

const filterOnClickPosition =(e) => {
    filterPositionParam = e.target.value;

}

const filterOnClickRecentTest =(e) => {
    filterRTParam = e.target.value;

}

const filterOnClickGender =(e) => {
    filterGenderParam = e.target.value;

}

let filterPositionParam = "";
let filterRTParam = "";
let filterGenderParam = "";

//체크박스 그리기
const IssueDate = ( {value , keyParam,  setfilterDate,filterDate,filterNotice,FilterListDivYnParam,isLoaded} ) => {
    const [trueFalseChecked,settrueFalseChecked] = useState(false);
    const [keyParamOfInput,setkeyParamOfInput] = useState(keyParam);

    let checkinput;
   // useEffect(setCheckedEvent(filterDate),[FilterListDivYnParam]);
//    useEffect(checkHandler( null,keyParam,value,filterDate,filterNotice,settrueFalseChecked,trueFalseChecked ),[]);
   // setCheckedEvent(keyParam,filterDate,trueFalseChecked,settrueFalseChecked);


    return (
        <>
            <input

                type="checkbox"
                name={"filterinputdate"}
                value={value}
                id={"filterinputdate"}
                key={keyParam}
                onChange={(e) => {checkHandler(e,keyParamOfInput,value,filterDate,filterNotice, settrueFalseChecked, trueFalseChecked);}}
                onLoad={setCheckedDate(filterDate,isLoaded)}
            />
            <label htmlFor={"filterinputdate"} className={"filterinputdatelabel"}>{value}</label>

        </>
    );
};

function setCheckedDate(filterDate, isLoaded){
    const filterinputdate = document.getElementsByName('filterinputdate');

    if (filterDate != undefined && filterDate != null && isLoaded) {
        if(filterDate == -1) filterDate = 0;
        filterinputdate[filterDate].checked = true;
    }

}

function setCheckedNotice(filterNotice, isLoadedNotice){
    const filterinputnotice= document.getElementsByName('filterinputnotice');


    if (filterNotice != undefined && filterNotice != null && isLoadedNotice) {
        if(filterNotice == -1){filterNotice = 0;}
        filterinputnotice[filterNotice].checked = true;
    }

}

function setCheckedPosition(filterPosition, isLoadedPosition){


    if (filterPosition != undefined && filterPosition != null && isLoadedPosition) {
        const selectPostion= document.getElementsByName('selectPostion');

        if(filterPosition == -1){filterPosition = 0;}
        selectPostion[filterPosition].selected = true;
    }

}

function setCheckedGender(filterGender, isLoadedGender){



    if (filterGender != undefined && filterGender != null && isLoadedGender) {
        const selectgender= document.getElementsByName('selectgender');

        if(filterGender == -1){filterGender = 0;}
        selectgender[filterGender].selected = true;
    }

}

function setCheckedRecentTestResult(filterRecentTestResult, isLoadedRecentTest){

    if (filterRecentTestResult != undefined && filterRecentTestResult != null && isLoadedRecentTest) {
        const selectRecentTest= document.getElementsByName('selectRecentTest');

        if(filterRecentTestResult == -1){filterRecentTestResult = 0;}
        selectRecentTest[filterRecentTestResult].selected = true;
    }

}



const IssueNotice = ( {value , keyParam,  setfilterNotice, filterNotice, isLoadedNotice}) => {

    return (
        <>
            <input
                type="checkbox"
                name={"filterinputnotice"}
                value={value}
                id={"filterinputnotice"}
                key={keyParam}
                onChange={(e) => checkHandler(e,keyParam)}
                onLoad={setCheckedNotice(filterNotice, isLoadedNotice)}
            />
            <label htmlFor={"filterinputnotice"} className={"filterinputdatelabel"}>{value}</label>

        </>
    );
};

const AdjustFilterComfirmButtun = ({setfilterDate, setfilterNotice,filterListIconOnClick,filterDate,isLoaded}) => {


    return(
            <span style={{float:"right"}}>
                <input type={"button"} value={"적용"} onClick={() => adjustFilterComfirmFunc({setfilterDate, setfilterNotice})}></input>
                <input type={"button"} value={"닫기"} onClick={filterListIconOnClick} ></input>

            </span>
           )
}

const AdjustFilterComfirmButtunEmployee = ({setfilterGender, setfilterRecentTest, setfilterPosition, filterListIconOnClick, textLangtablefileter}) => {
    return  (
                <span style={{float:"right"}}>
                    <input type={"button"} value={"적용"} onClick={() => adjustFilterComfirmFuncEmploee({setfilterPosition, setfilterGender,setfilterRecentTest})}></input>
                    <input type={"button"} value={"닫기"} onClick={filterListIconOnClick} ></input>
                </span>
            )
}

const adjustFilterComfirmFunc = ({setfilterDate, setfilterNotice}) => {
    setfilterDate(filterDateParam);
    setfilterNotice(filterNoticeParam);

}
const adjustFilterComfirmFuncEmploee = ({setfilterPosition, setfilterGender, setfilterRecentTest}) => {
    setfilterRecentTest(filterRTParam);
    setfilterPosition(filterPositionParam);
    setfilterGender(filterGenderParam);


}



const divStyleObj = {  width: "230px", float:"left"};

const checkHandler = ( e,keyParamOfInput,value,filterDate,filterNotice,settrueFalseChecked,trueFalseChecked ) => {
    if(filterDate == -1 || filterDate == 0){
        filterDate = "전체";
    }
    if(filterDate == 1){
        filterDate = "하루";
    }
    if(filterDate == 2){
        filterDate = "일주일";
    }
    if(filterDate ==3){
        filterDate = "한달";
    }

    const checkboxesDate = document.getElementsByName('filterinputdate')


    let checkedValue = trueFalseChecked;

    for (let i = 0; i < checkboxesDate.length; i++) {
        if (checkboxesDate[i].name == e.target.name && checkboxesDate[i] !== e.target) {
            checkboxesDate[i].checked = false                   //전체 false처리
            if(e != null || e != undefined){
                filterDateParam = checkedItemHandler(keyParamOfInput,e.target,filterDate);   //해당 값의 배열 번호 리턴
            }

        }
    }





     if(filterDate != null || filterDate != undefined ) {
         for (let i = 0; i < checkboxesDate.length; i++) {

             if (e.target.value == filterDate) { // filterDate = 0 keyParamOfInput = 0
                 if (filterDate == "전체" || filterDate == "All") {
                     if (checkboxesDate[0].checked == false) {
                         checkboxesDate[0].checked = true;
                     } else {
                         checkboxesDate[0].checked = false;
                     }
                 }
                 if (filterDate == "일일" || filterDate == "a day") {
                     if (checkboxesDate[0].checked == false) {
                         checkboxesDate[0].checked = true;
                     } else {
                         checkboxesDate[0].checked = false;
                     }
                 }
                 if (filterDate == "일주일" || filterDate == "a week") {
                     if (checkboxesDate[0].checked == false) {
                         checkboxesDate[0].checked = true;
                     } else {
                         checkboxesDate[0].checked = false;
                     }
                 }
                 if (filterDate == "한달" || filterDate == "a month") {
                     if (checkboxesDate[0].checked == false) {
                         checkboxesDate[0].checked = true;
                     } else {
                         checkboxesDate[0].checked = false;
                     }
                 }

             }
             /*if(filterDate == -1 && value == "전체"){
                 checkboxesDate[filterDate].checked = true;
                 filterDateParam = checkedItemHandler(keyParamOfInput,e.target);
             }else if(filterDate == value){
                 checkboxesDate[filterDate].checked = true;
                 filterDateParam = checkedItemHandler(keyParamOfInput,e.target);
             }else if(filterDate != value){
                 checkboxesDate[filterDate].checked =  false;
                 filterDateParam = checkedItemHandler(keyParamOfInput,e.target);
             }*/

         }
     }



    const checkboxesNotice = document.getElementsByName('filterinputnotice')
    for (let i = 0; i < checkboxesNotice.length; i++) {
        if (checkboxesNotice[i].name == e.target.name && checkboxesNotice[i] !== e.target) {
            checkboxesNotice[i].checked = false;
            if(e != null || e != undefined){
                filterNoticeParam = checkedItemHandler(keyParamOfInput,e.target);
            }
        }
    }
    if(filterNotice != null || filterNotice != undefined ){
        for (let i = 0; i < checkboxesNotice.length; i++) {
            checkboxesNotice[i].checked = false;
        }
            if(filterNotice == -1 && value == "전체"){
                checkboxesNotice[filterNotice].checked = true;
                filterDateParam = checkedItemHandler(keyParamOfInput,e.target);
            }else if(filterNotice == value){
                checkboxesNotice[filterNotice].checked = true;
                filterDateParam = checkedItemHandler(keyParamOfInput,e.target);
            }else if(filterNotice != value){
                checkboxesNotice[filterNotice].checked =  false;
                filterDateParam = checkedItemHandler(keyParamOfInput,e.target);
            }
    };

    bchecked = !bchecked;

    return checkedValue;
    if(e.target) {
        checkedItemHandler(keyParamOfInput, e.target);
    }
}

const checkedItemHandler = (keyParam, target) => {
    if (target.checked) {
       return keyParam;
    } else if (!target.checked) {
        return 0;
    }
};


class FilterListDiv extends  Component {


    render(){

    return (

        (this.props.table_title == "테스트센터 현황" || this.props.table_title == "Testcenter status") ?

            <div style={divStyleObj}>
                <FilterListDate props={this.props.props.props} ></FilterListDate>  <br/>
                <FilterListNotice props={this.props.props.props}></FilterListNotice>  <br/>
                <AdjustFilterComfirmButtun  setfilterDate={this.props.props.props.setfilterDate} filterDate={this.props.props.props.filterDate}  filterListIconOnClick={this.props.filterListIconOnClick} setfilterNotice={this.props.props.props.setfilterNotice}></AdjustFilterComfirmButtun>
            </div>

            :

            (this.props.table_title == "직원 검사 현황" || this.props.table_title == "Employee Inspection Status")
                ?
                <div style={divStyleObj}>
                    <FilterListPosition props={this.props.props.props} position_to_select={this.props.position_to_select} textLangtablefileter={this.props.textLangtablefileter}></FilterListPosition><br/>
                    <FilterListRecentTest props={this.props.props.props} filterRecentTestResult={this.props.props.props.filterRecentTestResult} textLangtablefileter={this.props.textLangtablefileter}></FilterListRecentTest><br/>
                    <FilterListGender props={this.props.props.props} filterGender={this.props.props.props.filterGender} textLangtablefileter={this.props.textLangtablefileter}></FilterListGender><br/><br/>
                    <AdjustFilterComfirmButtunEmployee filterListIconOnClick={this.props.filterListIconOnClick} setfilterGender={this.props.props.props.setfilterGenderFun} setfilterRecentTest={this.props.props.props.setfilterRecentTestResultFun} setfilterPosition={this.props.props.props.setfilterPositionFun} textLangtablefileter={this.props.textLangtablefileter}></AdjustFilterComfirmButtunEmployee>
                </div>
                : ''
    )
  };
}


let filterDateParam = 0;
let filterNoticeParam = 0;

let bchecked = false;

class FilterListDivWapper extends Component {

  state = {FilterListDivYnParam :true };
  FilterListIconOnClick = (e) => {
    this.setState({FilterListDivYnParam:this.state.FilterListDivYnParam ? false : true});


  }

  render(){
    return (

            (this.state.FilterListDivYnParam)
            ?
                 <Tooltip title="Filter list">
                    <IconButton onClickCapture={this.FilterListIconOnClick}>
                      <div >
                        <FilterListIcon >


                        </FilterListIcon>
                      </div>
                    </IconButton>
                 </Tooltip>
            :
                 <div className={"borderfilterdiv"}>
                    <FilterListDiv props={this.props} table_title={this.props.table_title}  FilterListDivYnParam={this.state.FilterListDivYnParam} t
                                   ableTestCenterOnClick={this.props.tableTestCenterOnClick} filterListIconOnClick={this.FilterListIconOnClick}
                                   YnParam ={true} position_to_select={this.props.position_to_select} filterDate={this.props.filterDate} filterNotice={this.props.filterNotice}
                                   setfilterRecentTest={this.props.setfilterRecentTest} setfilterGender={this.props.setfilterGender} setfilterPosition={this.props.setfilterPosition}
                                    filterPosition={this.props.filterPosition} filterRecentTestResult={this.props.filterRecentTestResult} filterGender={this.props.filterGender}
                    ></FilterListDiv>
                 </div>
        )
  }

}

function getDownloadCSV(filename,rows,selected) {
    var csv = [];
    var rowcsvheader = [];
    let row = [];

    if(filename.indexOf("직원 검사 현황")>-1){
        const Header = "직원 검사 현황\n\n\n"

        //1열에는 테이블 명
        rowcsvheader.push(
            "번호",
            "사번",
            "ID",
            "부서",
            "직급",
            "성별",
            "최근 검사 결과"
        );

        csv.push(rowcsvheader);


        rows.map((data, index) => {
            row = [];
            for(let i = 0; i< selected.length ; i++)
            {
                if (data.idx === selected[i]){
                    row.push(
                        data.idx,
                        data.staff_code == undefined ? data.employee_code : data.staff_code,
                        data.name,
                        data.department,
                        data.position,
                        data.gender,
                        data.recent_test_result,
                    );

                csv.push(row.join(","));
                }
            }
        });

        downloadCSV(csv.join("\n"), filename);
    }else if(filename.indexOf("테스트센터 현황")>-1){
        const Header = "테스트센터 현황\n\n\n"

        //1열에는 테이블 명

        rowcsvheader.push(
            "번호",
            "날짜 시간",
            "사번",
            "부서",
            "이름",
            "검사 결과",
            "통보",
        );

        csv.push(rowcsvheader);

        rows.map((data, index) => {
            row = [];
            for(let i = 0; i< selected.length ; i++)
            {
                if (data.nid === selected[i]){
                    row.push(
                        data.nid,
                        data.date +" "+data.test_time,
                        data.staff_code == undefined ? data.employee_code : data.staff_code,
                        data.department_name,
                        data.name,
                        data.test_result,
                        data.notified
                    );
                    csv.push(row.join(","));
                }
            }

        });

    downloadCSV(csv.join("\n"), filename);
    }

}


function downloadCSV(csv, filename) {
    var csvFile;
    var downloadLink;
    let fileNameEdited = filename;
    const date = new Date();
    const Today = moment(date).format('yyyy_MM_DD').toString()

    //한글 처리를 해주기 위해 BOM 추가하기
    const BOM = "\uFEFF";
    csv = BOM + csv;

    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename+"_"+Today;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
}



const EnhancedTableToolbar = (props,rows) => {
  const {numSelected, table_title} = props;

  return (
      <Toolbar
          sx={{
            pl: {sm: 2},
            pr: {xs: 1, sm: 1},
            ...(numSelected > 0 && {
              bgcolor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
          }}
      >
        {numSelected > 0 ? (
            <Typography
                sx={{flex: '1 1 100%'}}
                color="inherit"
                variant="subtitle1"
                component="div"
            >
              {numSelected} selected
            </Typography>
        ) : (
            <Typography
                sx={{flex: '1 1 100%'}}
                variant="h6"
                id="tableTitle"
                component="div"
            >
              {table_title }
            </Typography>
        )}

        {numSelected > 0 ?
            (
                <div style={{display: "inline-flex"}}>
                    <div><IconButton  onClick={props.selectedForDelete}><img src={deleteImg} style={{width:"30px",height:"30px"}} /></IconButton></div>
                        <Tooltip title="Download CSV" >
                          <IconButton onClick={() => getDownloadCSV(table_title,props.rows,props.selected)}>
                            <DownloadIcon/>
                          </IconButton>
                        </Tooltip>
                </div>
        ) : (props.table_title == '판매처별 현황' || props.table_title == 'Status by Vendors') ?
            ''
            : <FilterListDivWapper props={props} table_title={table_title}tableTestCenterOnClick={props.tableTestCenterOnClick}
                                   position_to_select={props.position_to_select} filterDate={props.filterDate} filterNotice={props.filterNotice} filterPosition={props.filterPosition} filterRecentTestResult={props.filterRecentTestResult} filterGender={props.filterGender} textLangtablefileter={props.textLangtablefileter}/>
        }
      </Toolbar>);
}
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  table_title : PropTypes.string,  
};
let selectedForDeleteRow = new Set();
let selectedForDeleteNidBefore = null;
let selectedForDeleteNidBeforeSet = new Set();
export default function TableWithSortAndAction2({table_title='판매처별 현황', rows, setfilterDate, setfilterNotice, filterDate, filterNotice, headerCells, tableCellsOfRow, tableTestCenterOnClick, setfilterPositionFun,
                                                    setfilterGenderFun, setfilterRecentTestResultFun, position_to_select,filterPosition, filterGender, filterRecentTestResult, settest_result_by_employee,settest_result_of_testcenterParam, textLangtablefileter}) {
  
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('staff_code');
  const [selected, setSelected] = React.useState([]);

  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);



  useEffect (()=> {
    setPage(0);
    setSelected([]);
  }, [rows])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.nid);

      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };
    const selectedForDelete = () => {

        if(selected.length > 1 && (table_title != "판매처별 현황" || table_title != "Status by vendors")) {
            alert('여러개의 행이 선택되었습니다.\n' +
                '삭제 할 데이터를 한개만 선택해주세요');
        }else{
            rows.map(async (row, index) => {
                if (row.nid == selectedForDeleteNidBefore) {
                    selectedForDeleteRow.clear();
                    selectedForDeleteRow.add(row);
                    if (table_title == "테스트센터 현황" || table_title == "Testcenter status") {
                        const result = await requestDeleteTableRowForTestCenter(row);

                        if (result.status === 200) {
                            rows.splice(rows.findIndex(i => i.nid == row.nid), 1);
                            alert('선택된 행이 삭제 되었습니다.');
                        } else {
                            alert('에러가 발생하여 삭제 되지 않았습니다.');
                        }
                    } else if (table_title == "직원 검사 현황" || table_title == "Employee Inspection Status" ) {
                        const result = await requestDeleteTableRowForMemberOfClinomic(row);

                        if (result.status === 200) {
                            rows.splice(rows.findIndex(i => i.nid == row.nid), 1);
                            alert('선택된 행이 삭제 되었습니다.');
                        } else {
                            alert('에러가 발생하여 삭제 되지 않았습니다.');
                        }

                    } else {
                        alert('아직 지원하지 않는 기능입니다.');
                    }
                }
            });
            if(table_title == "직원 검사 현황" || table_title == "Employee Inspection Status"){
                settest_result_by_employee(rows);
            }
            if(table_title == "테스트센터 현황" || table_title == "Testcenter status"){
                settest_result_of_testcenterParam(rows);
            }

        }


    }

  const handleClick = (event, nid,rows) => {

    const selectedIndex = selected.indexOf(nid);

    selectedForDeleteNidBefore = nid;
    if(selectedForDeleteNidBeforeSet.has(nid)){
        selectedForDeleteNidBeforeSet.delete(nid);
    }else{
        selectedForDeleteNidBeforeSet.add(nid);
    }
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, nid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);

  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (nid) => { return selected.indexOf(nid) !== -1;};

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  YnParam = false;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar rows={rows} numSelected={selected.length} selectedForDelete={selectedForDelete} filterPosition={filterPosition} filterRecentTestResult={filterRecentTestResult} filterGender={filterGender} table_title={table_title}
                              setfilterDate={setfilterDate} setfilterNotice={setfilterNotice} table_title={table_title} tableTestCenterOnClick={tableTestCenterOnClick}
                              headerCells={headerCells} tableCellsOfRow={tableCellsOfRow} rows={rows} setfilterPositionFun={setfilterPositionFun} setfilterGenderFun={setfilterGenderFun}
                              setfilterRecentTestResultFun={setfilterRecentTestResultFun} position_to_select={position_to_select} filterDate={filterDate} filterNotice={filterNotice} selected={selected} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='medium'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headerCells={headerCells}
            />  {rows.length == 0 ?  (table_title != "판매처별 현황" || table_title != "Status by Vendors") ? <TableBody><TableCellDense3 text-align={"center"} height={"50px"} colSpan={8}>데이터가 없습니다.</TableCellDense3></TableBody> : <TableBody><TableCellDense3 height={"50px"} colSpan={9}>데이터가 없습니다.</TableCellDense3></TableBody> :
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {



                  stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.nid);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRowDense2
                      hover
                      onClick={(event) => handleClick(event, row.nid,rows)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}                      
                    >

                    {tableCellsOfRow(row, labelId, isItemSelected)}


                    </TableRowDense2>
                  );
                })}
                {/*
              {emptyRows > 0 && (
                <TableRowDense
                  style={{
                    height:  53*emptyRows,
                  }}
                >
                  <TableCellDense colSpan={6} />
                </TableRowDense>
              )}
                */}
            </TableBody>}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </Box>
  );
};
