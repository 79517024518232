
import { sum } from "__lib/aTools";
import { subtotalOfAllTestResultType, totalTestCountFromObject } from "__lib/cTools";
import { TEST_RESULT_TYPE }  from '__config/dashboardConfig';
import { FaceIcon } from "__icons/IconProvider";

import { Typography , Stack} from '@mui/material'

import CardBasic  from  '_components_public/CardBasic';
import DounutChart_2 from "_components_chart/DounutChart_2";
import ProgessBar_1 from "_components_chart/ProgessBar_1";
import ChipsOfAllTestResult from "_components_public/ChipsOfAllTestResult";
import React from "react";


const totalTestCount = (list_by_department) => {
    return sum( list_by_department.map(row => subtotalOfAllTestResultType(row) ));
};

const overallTestResult = (field_analysis, total_test_cnt) => {
    if (!field_analysis || !field_analysis.result || Object.keys(field_analysis).length===0) {
        return [];
    };

    const _result = field_analysis.result;
    return Object.entries(TEST_RESULT_TYPE).map(
        //key value
        ([kk,vv]) => { return            ({
                idx : kk,
                label : TEST_RESULT_TYPE[kk].label,
                sub_total : _result[kk == "inconclusive" ? "indeterminate" : kk],
                content :  total_test_cnt != 0 ? `${(_result[kk == "inconclusive" ? "indeterminate" : kk]/total_test_cnt*100).toFixed(1)}% (${_result[kk == "inconclusive" ? "indeterminate" : kk]})` : ` -% (${_result[kk == "inconclusive" ? "indeterminate" : kk]})`,
                color :  TEST_RESULT_TYPE[kk].color,
                icon : <FaceIcon style={{color:'#666'}}/>,
            });})
};

const pieByTestResult = (overall_test_result) => {
    return overall_test_result.map( row => (
        {
            idx : row.idx,
            label : row.label,
            value : row.sub_total,
            color : row.color,
        }
    ));
};


export default function CardUser01_TestResult( {field_analysis,  list_by_period, list_by_department, sumOFFieldAnalysis,textLangSiteAnalysisCard,TEST_RESULT_TYPE, mode,sitecard}) {


    const num_of_row = list_by_period.length;

    const total_employee_cnt = sum(list_by_department.map( row => { return row.staff_count;})) * num_of_row; //전체 직원 수  //3 * 기간길이 //3 * 10 = 30
    const total_test_cnt = totalTestCount(list_by_department);  // 전체 테스트 갯수
    const overall_test_result = overallTestResult(field_analysis, total_test_cnt); //스택형 그래프 예상
    const pie_by_test_result = pieByTestResult(overall_test_result);  //도넛형 그래프

    let TEST_RESULT_TYPEset = TEST_RESULT_TYPE;

    const CardBody = (sumOFFieldAnalysis != 0) ? (
        <div style={{ height:'310px'}}>       
            <Stack direction="row" spacing={0}>
                <div align='center' style={{width:'200px', marginRight:'20px' , padding:'15px 0 0 0',}}>
                    <DounutChart_2 data ={pie_by_test_result} textLangAnalysisCard={textLangSiteAnalysisCard} />
                </div>

                <Stack direction="column" spacing={1.5}>
                    <div align='center' style={{padding: '5px 85px 0 15px'}}>
                        <ProgessBar_1 total={total_employee_cnt} progress={total_test_cnt}/>
                    </div>
                    <div>
                        <ChipsOfAllTestResult  rows = {overall_test_result} segment_name={''} segment_color={''} width='400px' height='100px' TEST_RESULT_TYPEset={TEST_RESULT_TYPEset} mode={mode} sitecard={sitecard} />
                    </div>
                </Stack>
            </Stack>
        </div> ) :
    (<div style={{padding:'0 20px 0 20px'}}>
        <div style={{width: "560px", height: "260px", "vertical-align": "middle", display: "table-cell", "text-align": "center"}}>{textLangSiteAnalysisCard.error1}</div>
    </div>
    
    )
    return (
        <>
        <CardBasic card_title={textLangSiteAnalysisCard.title} card_body={CardBody} style={{width:590, height:310}}/>
        </>

    )
}