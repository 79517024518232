
import {useContext, useEffect, useState} from 'react';

import {SiteContextProvider} from "__context_api/SiteContext";

import { 
    useFieldDepartment, 
    useFieldAnalysis, 
    useFieldTestCenter, 
    useFieldStaff,
    } from "__api/requestOneDropData";

import Header from "./Header";

import MenuSelect from 'main/SideNavigation'
import {langTextForDashboard} from "../__lang/lang";

import {BarChartIcon, MapIcon, PersonOutlineIcon} from "../__icons/IconProvider";

import OneDropDis01Analysis from "../containers/oneDropDis01Analysis";
import OneDropDis03RegionMap from "../containers/oneDropDis03RegionMap";
import OneDropDis02Seller from "../containers/oneDropDis02Seller";

import Onedrop01Analysis from "../containers/oneDrop01Analysis";
import Onedrop03TestCenter from "../containers/oneDrop03TestCenter";
import Onedrop02Employee from "../containers/oneDrop02Employee";
import ComponentArticleMain from "../article/onedropCSArticleBoardMain";

let lang ="en-US";
 let MENU_ITEMS = {

    'OneDropDis01Analysis' : {
        jsx : <OneDropDis01Analysis defultlang={lang}/>,
        label : '분석',
        icon : BarChartIcon ,
    },
    'OneDropDis03RegionMap' : {
        jsx : <OneDropDis03RegionMap defultlang={lang}/>,
        label : '지역 맵',
        icon :  MapIcon ,
    },
    'OneDropDis02Seller' : {
        jsx : <OneDropDis02Seller defultlang={lang}/>,
        label : '판매처',
        icon :  PersonOutlineIcon ,
    },

    'Onedrop01Analysis' : {
        jsx : <Onedrop01Analysis defultlang={lang}/>,
        label : '분석',
        icon : BarChartIcon ,
    },
    'Onedrop03TestCenter' : {
        jsx : <Onedrop03TestCenter defultlang={lang}/>,
        label : '테스트센터',
        icon : MapIcon ,
    },
    'Onedrop02Employee' : {
        jsx : <Onedrop02Employee defultlang={lang}/>,
        label : '멤버',
        icon : PersonOutlineIcon ,
    },
    'ComponentArticleMain' : {
        jsx : <ComponentArticleMain/>,
        label : 'CS게시판',
        icon : PersonOutlineIcon ,
    }
};



const dateToSelect = (periodical) => {
    if (!periodical || Object.keys(periodical).length===0) {
        return [];        
    };
    return Object.keys(periodical).sort();   
};

const departmentToSelect = (department) => {
    if (!department || Object.keys(department).length===0) {
        return [];        
    };
    return Object.keys(department); 
};


const departmentToSelect2 = (department) => {
    if (!department || typeof department !== 'object') {
        return [];        
    };
    return Object.entries(department).map(([code, row]) => ({idx:code, label:row.name})); 
};

const departments = (department) => {
    if (!department || typeof department !== 'object' ) {
        return {};        
    };
    return Object.fromEntries( 
        Object.entries(department).map(
            ([code, row]) => [ 
                code, 
                { idx:code, code:code, name:row.name, color: row.color.replace('0x', '#')}
            ]
        ));         
};

export default function OnedropUser () {

    const MENU_LIST_BY_USER_TYPE = ['Onedrop01Analysis','Onedrop03TestCenter', 'Onedrop02Employee'];
    //const MENU_LIST_BY_USER_TYPE = ['Onedrop01Analysis','Onedrop03TestCenter', 'Onedrop02Employee' ];
    const [menuSelected, setMenuSelected] = useState(MENU_LIST_BY_USER_TYPE[0]);

    const login_info_from_local = JSON.parse(window.localStorage.getItem("onedrop") || '{}');   
    const login_info = {
        token : 'access_token=b718f418d1ac4a49aa9b7201a35ed224', 
        site_key: login_info_from_local.site_key , //"onedrop",
    };

    const [defultlang,setdefultlang] = useState(lang);
    // lang = defultlang;
    let params = {};

    if(login_info.site_key ){

        if(login_info.site_key == 'onedrop'){
            params = {
                "site_key": login_info.site_key,
                "department_code": undefined,
                "start_time":"2020/01/01",
                "end_time":"2023/12/31",
                "periodical_type":"daily",
                "user_id_contains" : '1drop_'
            }
        }else{
            params = {
                "site_key": login_info.site_key,
                "department_code": undefined,
                "start_time":"2020/01/01",
                "end_time":"2023/12/31",
                "periodical_type":"daily",
                "user_id_contains" : login_info.site_key + '_',
            }
        }
    }else{
        params = {
            "site_key": undefined,
            "department_code": undefined,
            "start_time":"2020/01/01",
            "end_time":"2023/12/31",
            "periodical_type":"daily",

        }

    }
    const [accesstoken,setaccesstoken] = useState("");
    const [is_loading1, field_department] = useFieldDepartment(params);
    const [is_loading2, field_analysis] = useFieldAnalysis(params);
    const date_to_select = dateToSelect(field_analysis.periodical);
    const department_to_select = departmentToSelect(field_department.department);
    //언어 설정
    //let lang ="ko-KR";

    const langTextTotal = langTextForDashboard;
    const langTextKr = langTextForDashboard["ko-KR"];
    const langTextEn = langTextForDashboard["en-US"];


    let analysislang = lang;
    let textlang = langTextTotal[defultlang];

    let sideNavi = textlang.site.sidenavi;



    let testresulttype = textlang.testresulttype;

    let TEST_RESULT_TYPE ={
        'negative'  : {idx : 'negative' , label : testresulttype.label1, color : '#76F082',},//0
        'positive' : {idx : 'positive' , label : testresulttype.label2,  color : '#F16363',},//1
        'inconclusive' : {idx : 'inconclusive' ,label : testresulttype.label3,  color : '#FFD600',},//2
        'invalid' : {idx : 'invalid' ,label : testresulttype.label4,color : '#C1C1C1',},//3
        'cancel' : {idx : 'cancel' ,label : testresulttype.label5, color : '#EBEFF2',},//4
    }





    MENU_ITEMS = {

        'OneDropDis01Analysis': {
            jsx: <OneDropDis01Analysis defultlang={defultlang} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>,
            label: sideNavi.menu1,
            icon: BarChartIcon,
        },
        'OneDropDis03RegionMap': {
            jsx: <OneDropDis03RegionMap defultlang={defultlang} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>,
            label: sideNavi.menu2,
            icon: MapIcon,
        },
        'OneDropDis02Seller': {
            jsx: <OneDropDis02Seller defultlang={defultlang} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>,
            label: sideNavi.menu3,
            icon: PersonOutlineIcon,
        },

        'Onedrop01Analysis': {
            jsx: <Onedrop01Analysis defultlang={defultlang} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>,
            label: sideNavi.menu1,
            icon: BarChartIcon,
        },
        'Onedrop03TestCenter': {
            jsx: <Onedrop03TestCenter defultlang={defultlang} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>,
            label: sideNavi.menu2,
            icon: MapIcon,
        },
        'Onedrop02Employee': {
            jsx: <Onedrop02Employee defultlang={defultlang} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>,
            label: sideNavi.menu3,
            icon: PersonOutlineIcon,
        },
        'ComponentArticleMain': {
            jsx: <ComponentArticleMain defultlang={defultlang}/>,
            label: 'CS게시판',
            icon: PersonOutlineIcon,

        }


    }

    return (
        <SiteContextProvider 
                value = {{
                    login_info : login_info_from_local, //login_info,
                    field_department : field_department,
                    field_analysis : field_analysis,
                    is_loading_field_analysis : is_loading1,

                    date_to_select : date_to_select,
                    department_to_select : department_to_select,
                    department_to_select2 : departmentToSelect2(field_department.department || {}),
                    departments : departments(field_department.department || {}),
                    lang:defultlang,
                    textOflang:langTextTotal,
                    accesstoken:accesstoken,
                    setaccesstoken:setaccesstoken
                    }} >
            <div style={{ height:'auto', paddingBottom:'20px'}}>

                <div style={{
                        float:'left', 
                        width:170, height:500, 
                        marginTop:0, 
                        border: 'solid 0px blue'}}> 
                    <MenuSelect
                        menu_list_by_user_type={MENU_LIST_BY_USER_TYPE} 
                        menuSelected={menuSelected} 
                        setMenuSelected={setMenuSelected}
                        login_info = {login_info_from_local}
                        lang={defultlang}
                        textOflang={langTextTotal}
                        mode={"site"}
                        />                                                                                            
                </div>

                <div style={{marginLeft:172, width:1040, height:'auto', border: 'solid 0px grey'}}>                 
                    <Header label={MENU_ITEMS[menuSelected].label} defultlang={defultlang} setdefultlang={setdefultlang}/>
                    <div >
                        {MENU_ITEMS[menuSelected].jsx}
                    </div>                   
                </div>

            </div>
        </SiteContextProvider>
    )    
};




export {lang as langUser};