import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Typography} from '@mui/material';

import { withStyles } from '@mui/styles';
//import { makeStyles } from '@material-ui/core';

//import {randomColor} from '__lib/aTools';

/*

const TableRowDense = withStyles((theme) => ({
    root: {
      height: '26px',    
    }
  }))(TableRow);

const TableCellDense = withStyles((theme) => ({
    root: {
      padding: "0px 16px",
      minWidth : 55,
    }
  }))(TableCell);
*/



export default function TableBriefByUseOfDist({rows, textLangAnalysisresultbox})  {
    const headers = ['', textLangAnalysisresultbox.text1];
  return (
    <TableContainer   >
      <Table 
            sx={{ minWidth: 230, maxWidth:230, }} aria-label="simple table"
            style = {{ paddingTop:0, paddingBottom:0, marginTop:0, marginBottom:0 , }}
            >
        <TableHead style={{height:'48px'}}>
          <TableRow style={{height:'26px',  padding:'0px 0px 0px 0px'}}>
              {
                  headers.map( (row, index) => {
                      return <TableCell key={index} align="right" style={{colWidth:'100px', height:'15px', padding:'0px 16px'}}>{row}</TableCell>
                  })
              }
          </TableRow>
        </TableHead>
        <TableBody  >
          
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ 
                  '&:last-child td, &:last-child th': { border: 0 },                    
                 }} 
              style={{height:'26px',  }}
                 
            >
              <TableCell component="th" scope="row" style = {{ minWidth:100,  height:'15px',  padding:'0px 16px ' }}  >
                  <div
                    style={{
                        width: '120px',
                        height : '20px',
                        backgroundColor: row.color, //randomColor(),
                        xopacity: '0.5',
                        borderRadius: '4px',
                        overflow: 'hidden', 
                        marginLeft:-15,                                           
                    }}                    
                  >
                    <Typography variant='h7'  style={{paddingLeft:10, color:'black', opacity:'1', textAlign:'center',}}>{row.label}</Typography>
                    </div>               
              </TableCell>   
         
              <TableCell align="right" style={{height:'15px',  padding:'0px 16px '}}>{row.use}{textLangAnalysisresultbox.text2}</TableCell>

            </TableRow>
          ))}
                 
        </TableBody>
      </Table>
    </TableContainer>
  );
}