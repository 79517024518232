import React, { PureComponent } from 'react';
import { BarChart, Bar, LabelList, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const sample_data = [
    {idx : 'prod', label : '생산', cnt : Math.floor(Math.random()*100) + 30, },
    {idx : 'design', label : '디자인', cnt : Math.floor(Math.random()*100) + 30, },
    {idx : 'cs', label : 'CS', cnt : Math.floor(Math.random()*100) + 30, },
    {idx : 'sales', label : '영업', cnt : Math.floor(Math.random()*100) + 30, },
    {idx : 'marketing', label : '마케팅', cnt : Math.floor(Math.random()*100) + 30, },
    {idx : 'admin', label : '경영지원', cnt : Math.floor(Math.random()*100) + 30, },
    {idx : 'rnd', label : 'R&D', cnt : Math.floor(Math.random()*100) + 30, },
    {idx : 'international', label : '해외업무', cnt : Math.floor(Math.random()*100) + 30, },
    {idx : 'hnr', label : '인력개발', cnt : Math.floor(Math.random()*100) + 30, },
    
];



const SVGTextLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
    return (
        <g>          
          <text x={x + width / 2} y={y-10} y={y+height-10} fill="black" textAnchor="middle" dominantBaseline="middle">
            {value}
          </text>
        </g>
    );
    //return <div> {value}aaa </div>
};

const SVGTextLabel2 = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
    return (
        <g>          
          <text x={x + width / 2} y={y+height+10}  fill="red" textAnchor="middle" dominantBaseline="middle">
            {value}
          </text>
        </g>
    );
    //return <div> {value}aaa </div>
};

export default function BarTiny ({width=500, height=200, data=sample_data, isAnimationActive=true}) {


  const [hilightedBar, setHilightedBar] = React.useState('');
  const onHilightBar = (e) => {

  };

    return (
      <div style={{width:'100%', height:"100%"}}>
        <BarChart 
            width={width} height={height} data={data}
            barSize={60}
            barCategoryGap={0}
            barGap={0}
        >

        <XAxis 
        dataKey="label" 
        minTickGap = {-1000}

        />
        <Tooltip cursor={{fill: '#fff'}} />

          <Bar 
            isAnimationActive={isAnimationActive}
            dataKey="cnt" fill="#8884d8"  
            onMouseEnter = {(bar_obj) => setHilightedBar(bar_obj.idx)}
            onMouseLeave = {() => setHilightedBar('')}
            xbarSize = {20}
            xbarCategoryGap={100} xbarGap={100}
            >               

            <LabelList dataKey="cnt" position="insideBottom" content={SVGTextLabel}/>
            {data.map((entry, index) => (
                <Cell cursor="pointer" fill={(entry.idx===hilightedBar) ? '#109CF1' : '#ccc'} key={`cell-${index}`} />
              ))}

        </Bar>
        </BarChart>
      </div>
    );
}
