
//import axios from 'axios';
import { useState, useEffect } from "react";
import {
    requestPostIgnoreSSL,
    requestPost,
    requestPostWithCredentials,
    requestPostWithCredentialsdetail
} from "__lib/httpRequest";



const parseData = (data) => {    
    return data;
};

export function useRequestAPI2 (url, params, headers, render_key='none' ) { 

    const [rtn, setRtn] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const callback = (data) => {

        setRtn(parseData(data));


        setIsLoading(false);
    };

    useEffect(()=>{
        setIsLoading(true);
        requestPostWithCredentials(url, params, headers, callback);


    },[render_key]);
   // },[]);
    return [isLoading,rtn] ;
};

export function useRequestAPI2forDetail2 (url, params, headers,reload, render_key='none' ) {

    const [rtn, setRtn] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isreload, setisreload] = useState(false);
    if(reload){setisreload(reload);}

    const callback = (data) => {

        setRtn(parseData(data));


        setIsLoading(false);
        setisreload(false);
    };

    useEffect(()=>{

        requestPostWithCredentials(url, params, headers, callback);


        //},[render_key]);
    },[isreload]);
    return [rtn, setRtn,isLoading, setIsLoading] ;
};

export function useRequestAPI2ForDetail (url, params, headers, render_key='none' ) {

    const [rtn, setRtn] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const callback = (data) => {

        setRtn(parseData(data));
        setIsLoading(false);
    };


    useEffect(()=>{
        setIsLoading(true);
        requestPostWithCredentialsdetail(url, params, headers, callback);



    },[]);

     return [isLoading, rtn] ;
};