
import { useHistory } from 'react-router-dom'; 

import {Button} from "@mui/material";
import {requestLogOut} from "__api/requestLogOut";

import {Typography, Stack} from "@mui/material";
import {useState} from "react";

export default function Header ({label='분석', defultlang, setdefultlang}) {
    let history = useHistory();
    const [langheader,setlangheader]= useState(defultlang);
    const onLogOut = () => {
        requestLogOut();     
        history.push('/signin');     
    };



    const changeLang = () => {

        if(defultlang =="ko-KR"){
            setdefultlang("en-US");
        }else{
            setdefultlang("ko-KR");
        }


    }
    let language
    if(defultlang =="ko-KR"){
        language = "KOREAN"
    }else{
        language = "ENGLISH"
    }

    return (        
        <div style={{paddingTop:'12px', height:'40px'}}>
            <div align='right' style={{float:'right', padding:'5px 20px 0 0', height:'30px', border: 'solid 0px grey'}}>               

                <Button ariant="outlined" size='small' onClick={() => changeLang({defultlang,setdefultlang})}>{language}</Button>
                <Button variant="outlined" size='small' onClick={()=>onLogOut()}>
                    logout
                </Button>            
            </div>  
            <Stack direction="row" spacing={0}>
                <Typography style={{color:'#ABABAB', fontSize:'12px'}}>1drop / </Typography>
                <Typography style={{color:'#109CF1', fontSize:'12px', paddingLeft:'5px'}}>{label}</Typography>
            </Stack>
        </div>
    );
};