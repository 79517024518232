import * as React from 'react';
//import { styled } from '@mui/material/styles';

import {Paper, Container,  List, ListItem, Grid, Stack} from "__ui_components/UIComponents";
import { Typography} from "@mui/material";

import { FaceIcon } from '__icons/IconProvider';

import ChipOfTestResult from "_components_public/ChipOfTestResult";  

const default_data = [
    {idx : 'negative' , label : '음성', content: '93% | 637', icon : <FaceIcon style={{color:'#666'}}/>,},
    {idx : 'positive' , label : 'Positive', content: '2% | 19', icon :  <FaceIcon style={{color:'#666'}}/>,},
    {idx : 'uncertain' ,label : '불확정', content: '3% | 29', icon :  <FaceIcon style={{color:'#666'}}/>,},
    {idx : 'invalid' ,label : '무효', content: '1% | 11', icon :  <FaceIcon style={{color:'#666'}}/>,},
    {idx : 'cancel' ,label : '취소', content: '1% | 11', icon :  <FaceIcon style={{color:'#666'}}/>,},
];

export default function ChipsOfAllTestResult ({rows, segment_name='' , segment_color='', width='200px', height='150px', TEST_RESULT_TYPEset , mode, sitecard}) {

    rows = rows || default_data;

    let koORen;




        if (typeof(TEST_RESULT_TYPEset) !== "undefined" && TEST_RESULT_TYPEset[0] == "negative") {
            koORen = "en-US";
        } else {
            koORen = "ko-KR";
        }
        let TEST_RESULT_TYPEsetobj = TEST_RESULT_TYPEset;


        if (mode = "site" && sitecard == "01") {

            return (
                <>

                    <Typography style={{
                        marginBottom: '5px',
                        backgroundColor: segment_color,
                        fontSize: '15px',
                    }}>
                        {segment_name}
                    </Typography>
                    <Grid container spacing={0.5} style={{height: height, minWidth: width}}>
                        {
                            rows.map((row, index) => {
                                return (
                                    <Grid item style={{paddingLeft: '5px'}} key={index}>
                                        <Stack direction="row" spacing={0}>
                                            <div style={{width: 65, textAlign: "center",}} align='left'>
                                                <ChipOfTestResult idx={row.idx}
                                                                  TEST_RESULT_TYPE2={TEST_RESULT_TYPEsetobj}/>
                                            </div>

                                            {


                                                (index == 0 || index == 2 || index == 4) ?
                                                    <Typography variant='h7' style={{
                                                        width: 125,
                                                        textAlign: "right",
                                                    }}>{!isNaN(row.content) ? "-" : row.content}</Typography>

                                                    :
                                                    <Typography variant='h7' style={{
                                                        width: 115,
                                                        textAlign: "center",
                                                    }}>{!isNaN(row.content) ? "-" : row.content}</Typography>

                                            }
                                        </Stack>
                                    </Grid>
                                );

                            })
                        }
                    </Grid>


                </>
            )
        } else if (mode = "site" && sitecard == "04") {

            return (
                <>

                    <Typography style={{
                        marginBottom: '5px',
                        backgroundColor: segment_color,
                        fontSize: '15px',
                    }}>
                        {segment_name}
                    </Typography>
                    <Grid container spacing={0.5} style={{height: height, minWidth: width}}>
                        {
                            rows.map((row, index) => {
                                return (
                                    <Grid item style={{paddingLeft: '5px'}} key={index}>
                                        <Stack direction="row" spacing={0}>
                                            <div style={{width: 65, textAlign: "center",}} align='left'>
                                                <ChipOfTestResult idx={row.idx}
                                                                  TEST_RESULT_TYPE2={TEST_RESULT_TYPEsetobj}/>
                                            </div>
                                            <Typography variant='h7' style={{
                                                width: 120,
                                                textAlign: "center",
                                            }}>{!isNaN(row.content) ? "-" : row.content}</Typography>
                                        </Stack>
                                    </Grid>
                                );

                            })
                        }
                    </Grid>


                </>
            )


        } else {

            return (
                <>

                    <Typography style={{
                        marginBottom: '5px',
                        backgroundColor: segment_color,
                        fontSize: '15px',
                    }}>
                        {segment_name}
                    </Typography>
                    <Grid container spacing={0.5} style={{height: height, minWidth: width}}>
                        {
                            rows.map((row, index) => {
                                return (
                                    <Grid item style={{paddingLeft: '5px'}} key={index}>
                                        <Stack direction="row" spacing={0}>
                                            <div style={{width: 65, textAlign: "center",}} align='left'>
                                                <ChipOfTestResult idx={row.idx}
                                                                  TEST_RESULT_TYPE2={TEST_RESULT_TYPEsetobj}/>
                                            </div>
                                            <Typography variant='h7' style={{
                                                width: 130,
                                                textAlign: "right",
                                            }}>{!isNaN(row.content) ? "-" : row.content}</Typography>
                                        </Stack>
                                    </Grid>
                                );

                            })
                        }
                    </Grid>


                </>
            )


        }



    };



