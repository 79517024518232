import React  from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import {Typography} from '@mui/material';

import {sum} from '__lib/aTools';

const sample_data = [
  { label: 'Group A', value: 400, color : '#0088FE' },
  { label: 'Group B', value: 300, color : '##00C49F' },
  { label: 'Group C', value: 300, color : '#FFBB28' },
  { label: 'Group D', value: 200 , color : '#FF8042' },
];

/*
{
                idx : row.idx,
                label : row.label,
                value : row.sub_total,
                color : row.color,
            }
*/

export default function DounutChart_2 ({data=sample_data,textLangAnalysisCard}) {

  const total_test_count = sum(data.map(row => row.value));

  return (
        <div style={{position: 'relative'}}>
        <PieChart 
          width={200} height={200} 
          >
          <Pie
            data={data}
            cx={100}
            cy={100}
            innerRadius={75}
            outerRadius={95}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value"
            animationDuration={500}
          >
            {data.map((entry, index) => {

              return <Cell key={`cell-${index}`} fill={entry.color} />
              })}
          </Pie>
          </PieChart>

        <Typography
          variant='h7'
          style={{
            position: 'absolute',
            zIndex: 2,
            marginTop: '0px',
            display: 'block',
            left: 55,
            top: 70,
            }}>
            {textLangAnalysisCard.text1}
                        </Typography>
          <div style={{
            position: 'absolute',
            zIndex: 2,
            marginTop: '0px',
            display: 'inline-block',
            left: 53,
            top: 78,
            
            width : '100px',
            }}>
            <Typography style={{fontFamily:'Noto Sans KR', fontWeight:700, fontStyle:'normal', fontSize : '45px', fontColor: "#192A3E",}}>
              {total_test_count || ''} 
            </Typography>
          </div>
        </div>
      );
};
