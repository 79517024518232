//import './typo.css';

import { withStyles } from '@mui/styles';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack  from '@mui/material/Stack';

import Paper from '@mui/material/Paper';

import MuiTypography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';

import TextField from '@mui/material/TextField';

import Collapse from '@mui/material/Collapse';

import Divider from '@mui/material/Divider';

import Chip from '@mui/material/Chip';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import styled from '@emotion/styled';
//import styled from 'styled-components';

/*
import WebFont from 'webfontloader';
WebFont.load({
  google: {
    families: ['Noto Sans KR:300,400,500', 'sans-serif']
  }
});
*/

/*
const TableRowDense = withStyles((theme) => ({
  root: {
    height: 26,

  }
}))(TableRow);
*/

/*
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-weight: 300;
  */

/*
const Typography = withStyles((theme) => ({
  root: {
      color: "#192A3E",
      fontFamily: ['Noto Sans KR', 'sans-serif'],
      fontStyle: 'normal',
      fontWeight: 300,
  }
}))(MuiTypography);


const TypographyA = withStyles((theme) => ({
  root: {
      color: "#192A3E",
      fontFamily: ['Noto Sans KR', 'sans-serif'],
      fontStyle: 'normal',
      fontWeight: 500,
  }
}))(MuiTypography);
*/

const Typography = styled(MuiTypography)`
  color: #192A3E;
  font-family: 'Noto Sans KR', 'sans-serif';
  font-style: normal;
  font-weight: 300;
`;

const TypographyA = styled(MuiTypography)`
  color: #192A3E;
  font-family: 'Noto Sans KR', 'sans-serif';
  font-style: normal;
  font-weight: 500;
`;


/*
const TextCardTitle = withStyles((theme) => ({
  root: {
      color: "#192A3E",
      fontFamily: ['Noto Sans KR', 'sans-serif'],
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize : '15px',
  }
}))(MuiTypography);
*/

const TextCardTitle = styled(MuiTypography)`
  color: #192A3E;
  font-family: 'Noto Sans KR', 'sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size : 15px;
`;



const TextFieldwithBG = withStyles((theme) => ({
    root: {
        backgroundColor: "#fff"
    }
  }))(TextField);




export { Box, Grid,Container, Stack, Paper };
export { MenuItem, Menu, List, ListItem };
export { InputLabel,FormHelperText ,FormControl , Select, Switch, TextField, TextFieldwithBG, Button, IconButton };
export { Collapse };
export { Divider, Chip };
export { Typography, TypographyA, MuiTypography, TextCardTitle };
export {Card, CardContent, CardActions};


// Box, List, ListItem, ListItemButton,  Divider, ListItemText, ListItemIcon, ListItemAvatar, Avatar, IconBut