
import {FaceIcon, BarChartIcon, MapIcon, PersonOutlineIcon, } from "__icons/IconProvider";

import { useContext, DistributorContext } from '__context_api/DistributorContext';

import {Button, Stack, Typography, TypographyA} from "__ui_components/UIComponents"; //'@mui/material';
//import { withStyles } from '@mui/styles';
//import MuiTypography from '@mui/material/Typography';

// import Distributor01Analysis from 'containers/Distributor01Analysis';
// import Distributor02Seller from 'containers/Distributor02Seller';
// import Distributor03RegionMap from 'containers/Distributor03RegionMap';

import OneDropDis01Analysis from 'containers/oneDropDis01Analysis';
import OneDropDis02Seller from 'containers/oneDropDis02Seller';
import OneDropDis03RegionMap from 'containers/oneDropDis03RegionMap';

// import EndUser01Analysis from 'containers/EndUser01Analysis';
// import EndUser02Employee from 'containers/EndUser02Employee';
// import EndUser03TestCenter from 'containers/EndUser03TestCenter';

import Onedrop01Analysis from 'containers/oneDrop01Analysis';
import Onedrop03TestCenter from 'containers/oneDrop03TestCenter';
import Onedrop02Employee from 'containers/oneDrop02Employee';


// import ComponentArticle from 'article/onedropCSArticleBoard';
// import ComponentArticleDelete from 'article/onedropCSArticleBoardDelete';
// import ComponentArticleDetail from "article/onedropCSArticleBoardDetail";
// import ComponentArticleModify from "article/onedropCSArticleBoardModify";
// import ComponentArticleWrite from "article/onedropCSArticleBoardWrite";
// import ComponentArticleMain from "article/onedropCSArticleBoardMain";
import {useEffect, useState} from "react";
import {SiteContext} from "../__context_api/SiteContext";

let langjsx ="en-US";

 export let MENU_ITEMS = {
    // 'Dis01Analysis' : {
    //     jsx : <Distributor01Analysis/>,
    //     label : '분석',
    //     icon : BarChartIcon ,
    // },
    // 'Dis03RegionMap' : {
    //     jsx : <Distributor03RegionMap/>,
    //     label : '지역 맵',
    //     icon :  MapIcon ,
    // },
    // 'Dis02Seller' : {
    //     jsx : <Distributor02Seller/>,
    //     label : '판매처',
    //     icon :  PersonOutlineIcon ,
    // },
    'OneDropDis01Analysis' : {
        jsx : <OneDropDis01Analysis defultlang={langjsx == "ko-KR" ? "en-US" : "ko-KR"}/>,
        label : '분석',
        icon : BarChartIcon ,
    },
    'OneDropDis03RegionMap' : {
        jsx : <OneDropDis03RegionMap defultlang={langjsx}/>,
        label : '지역 맵',
        icon :  MapIcon ,
    },
    'OneDropDis02Seller' : {
        jsx : <OneDropDis02Seller defultlang={langjsx}/>,
        label : '판매처',
        icon :  PersonOutlineIcon ,
    },
    // 'User01Analysis' : {
    //     jsx : <EndUser01Analysis/>,
    //     label : '분석',
    //     icon : BarChartIcon ,
    // },
    // 'User03TestCenter' : {
    //     jsx : <EndUser03TestCenter/>,
    //     label : '테스트센터',
    //     icon : MapIcon ,
    // },
    // 'User02Employee' : {
    //     jsx : <EndUser02Employee/>,
    //     label : '멤버',
    //     icon : PersonOutlineIcon ,
    // },
    'Onedrop01Analysis' : {
        jsx : <Onedrop01Analysis defultlang={"ko-KR"}/>,
        label : '분석',
        icon : BarChartIcon ,
    },
    'Onedrop03TestCenter' : {
        jsx : <Onedrop03TestCenter defultlang={langjsx}/>,
        label : '테스트센터',
        icon : MapIcon ,
    },
    'Onedrop02Employee' : {
        jsx : <Onedrop02Employee defultlang={langjsx}/>,
        label : '멤버',
        icon : PersonOutlineIcon ,
    }
    // 'ComponentArticleMain' : {
    //     jsx : <ComponentArticleMain/>,
    //     label : 'CS게시판',
    //     icon : PersonOutlineIcon ,
    // }
};



// style={{fontFamily:'Noto Sans KR', fontWeight:500, fontColor: "#192A3E",}}
// ['Dis01Analysis', 'Dis03RegionMap','Dis02Seller',] ['User01Analysis','User03TestCenter', 'User02Employee' , ]

const ItemsToSelect = ({menu_list, menuSelected, setMenuSelected=()=>{}}) => {
    return (
        <Stack direction="column" spacing={0}>
        {
            menu_list.map( 
                (menu_item, index) => {

                    const SubMenuIcon = MENU_ITEMS[menu_item].icon;
                    const icon_color = (menu_item===menuSelected) ? '#109CF1' : '#C2CFE0';
                    const font_color = (menu_item===menuSelected) ? '#109CF1' : '#334D6E';
                    return (
                    <div key={index} align='left' style={{ padding:'0 0 0 0px', marginLeft:'-10px'}}>
                    <Button onClick={()=>setMenuSelected(menu_item)} style={{display:'flex', justifyContent:'start', minWidth: '140px'}}>
                        <SubMenuIcon style={{color:icon_color}}/>
                        <TypographyA  style={{fontFamily:'Noto Sans KR', color:font_color, paddingLeft:'10px', fontSize:'13px'}}>{MENU_ITEMS[menu_item].label}</TypographyA>
                    </Button>
                    </div>
                    );
                })
        } 
        </Stack>
    );
};


export default function MenuSelect ({
        menu_list_by_user_type=['Dis01Analysis', 'Dis03RegionMap','Dis02Seller'],
        menuSelected, 
        setMenuSelected,
        login_info,
        lang,
        textOflang,
        mode
    }) {




    let analysislang = lang;
    let textlang = textOflang[lang];

    let sideNavi = textlang.site.sidenavi;
    if(mode == "site"){
        sideNavi = textlang.site.sidenavi;
    }else if(mode == "distributor"){
        sideNavi = textlang.distributor.sidenavi;
    }
    useEffect(() =>{

    },[])




    MENU_ITEMS = {

        'OneDropDis01Analysis' : {
            jsx : <OneDropDis01Analysis defultlang={lang}/>,
            label : sideNavi.menu1,
            icon : BarChartIcon ,
        },
        'OneDropDis03RegionMap' : {
            jsx : <OneDropDis03RegionMap defultlang={lang}/>,
            label : sideNavi.menu2,
            icon :  MapIcon ,
        },
        'OneDropDis02Seller' : {
            jsx : <OneDropDis02Seller defultlang={lang}/>,
            label : sideNavi.menu3,
            icon :  PersonOutlineIcon ,
        },

        'Onedrop01Analysis' : {
            jsx : <Onedrop01Analysis defultlang={lang}/>,
            label : sideNavi.menu1,
            icon : BarChartIcon ,
        },
        'Onedrop03TestCenter' : {
            jsx : <Onedrop03TestCenter defultlang={lang}/>,
            label : sideNavi.menu2,
            icon : MapIcon ,
        },
        'Onedrop02Employee' : {
            jsx : <Onedrop02Employee defultlang={lang}/>,
            label : sideNavi.menu3,
            icon : PersonOutlineIcon ,
        }
    }







    return (
        <div style={{padding:'14px 0px 0px 19px'}}>
            <div align='left'>
                <TypographyA  style={{ fontSize:'18px',fontWeight:600, paddingBottom:'20px'}} >{lang == "ko-KR" ? "원드롭" : "1Drop"}</TypographyA>
                <TypographyA  style={{fontSize:'14px', paddingBottom:'5px'}}>{login_info.name}</TypographyA>
                <TypographyA style={{color:"#90A0B7", fontSize:'11px'}}>{login_info.email}</TypographyA>
                <TypographyA style={{color:"#90A0B7", fontSize:'11px'}}>{login_info.homepage}</TypographyA>
            </div>
            <div style={{paddingTop:'20px'}}>
                <ItemsToSelect menu_list={menu_list_by_user_type} menuSelected={menuSelected} setMenuSelected={setMenuSelected}/>
            </div>
        </div>
    );
};


