
import { sum , randomBetween } from '__lib/aTools';
//import { useContext, DistributorContext } from '__context_api/DistributorContext';

import { Typography, Stack } from '@mui/material'
import CardBasic  from  '_components_public/CardBasic';

import  ProgessBar_1   from '_components_chart/ProgessBar_1';
import TableBriefByUseOfDist from "_components_table/TableBriefByUseOfDist";

const list_by_vendor = (by_vendor) => {
    if (!by_vendor || Object.keys(by_vendor).length===0) {
        return [];
    };
    return Object.entries(by_vendor).map( ([kk,vv]) => (
        {
            idx : kk,
            label: vv.name, 
            name : vv.name,
            color : vv.color.replace('0x','#'),
            sell : vv.use_count + randomBetween(0, vv.use_count),
            use : vv.use_count,          
        }
        ));
};


export default function CardDist02_CountOfUse({location_analysis, sumOFFieldAnalysis, textLangAnalysisresultbox}) {

    //const { location_analysis, login_info }  = useContext(DistributorContext); 
    const by_vendor = location_analysis.vendor;
    const use_by_vendor = list_by_vendor(by_vendor);
    const total_sell = location_analysis.sale_count;   //sum( use_by_vendor.map(row => row.sell));
    const total_use = sum( use_by_vendor.map(row => row.use));


    const CardBody = (sumOFFieldAnalysis != 0) ?
        (
            <>
            <div style={{width:'100%', height:'310px'}}>
                <Stack direction="row" spacing={2}>
                    <div style={{ width:'200px', padding:'50px 35px 0 35px' , }}>
                        <ProgessBar_1  total={total_sell} progress={total_use}  />
                        </div>
                    <div>
                        <TableBriefByUseOfDist rows={use_by_vendor} textLangAnalysisresultbox={textLangAnalysisresultbox}/>
                        </div>

                </Stack>
            </div>
        </>) :
        (<div style={{padding:'0 20px 0 20px'}}>
                <div style={{width: "420px", height: "260px", "vertical-align": "middle", display: "table-cell", "text-align": "center"}}>{textLangAnalysisresultbox.error1}</div>
            </div>)




    return (
        <>
        <CardBasic card_title={textLangAnalysisresultbox.title} card_body={CardBody} style={{width:515, height:310}}/>
        </>

    );
};