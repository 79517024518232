
import React, {useEffect, useState} from 'react';

import { useContext, DistributorContext } from '__context_api/DistributorContext';
import {
    useLocationMapAnalysis,
} from "__api/requestOneDropDistributorData";

import {Box, TextField, TextFieldwithBG, Button, Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";
import './03RegionMap.css'
import SelectItemByLabel from "_components_public/SelectItemByLabel";
import MuiDatePicker from "_components_public/MuiDatePicker";

import LocationOnMap  from "_components_GoogleMap/LocationOnMap";
import CardMap01_MapSide from '_components_card/CardMap01_MapSide';
import UntilIsLoading from "_components_public/UntilIsLoading";
import {setStopOnClusteringEndYnParam,  stopOnClusteringEndYnParam} from "_components_GoogleMap/LocationOnMap";


function InputFormSearchDist03({ date_to_select, vendor_to_select, vendor_to_select2, handleConfirm, textLangdistributorrRegionmap}) {

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [vendor, setVendor] = useState('');

    const onClickConfirm = () => {
        handleConfirm({
            date_from : dateFrom,
            date_to : dateTo,
            vendor : (vendor == "전체" || vendor == "All") ? '' : vendor,
        });
        setStopOnClusteringEndYnParam(false);
    };

    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, width: '200px'  },
                display : 'flex',
            }}
            noValidate
            autoComplete="off">
            <SelectItemByLabel input_label={textLangdistributorrRegionmap.box1} value={vendor} item_to_select={vendor_to_select2}  handleChange={setVendor}
                               textLangAnalysissearch={textLangdistributorrRegionmap} />
            <MuiDatePicker label={textLangdistributorrRegionmap.box3} passValue={setDateFrom} />
            <MuiDatePicker label={textLangdistributorrRegionmap.box4} passValue={setDateTo} />
            <Button sx={{maxWidth:50}} variant='contained' onClick={()=>onClickConfirm()}>{textLangdistributorrRegionmap.submit}</Button>

        </Box>
    );
};

const analysisByZoom = (analysis) => {
    if (!analysis || Object.keys(analysis).length===0) {
        return {};
    };
    const toByZoom = (by_zoom) => by_zoom.map(
        row => (
            {
                lat : row.grid_center_latitude,
                lng : row.grid_center_longitude,
                count : row.count,
                max_count : Math.max(...by_zoom.map(row => row.count)),

            })
    );
    return (
        Object.fromEntries(
            Object.entries(analysis).map(
                ([zoom, data]) => [zoom, toByZoom(data.grid || [])]
            )
        )
    );
};

const dotArray = (result) => result.map(
    row => (
        {
            lat : row.grid_center_latitude == ""? null :  parseFloat(row.grid_center_latitude),    // parseFloat((Math.random()*(lat_max-lat_min) + lat_min).toFixed(5)),
            lng : row.grid_center_longitude == "" ? null : parseFloat(row.grid_center_longitude),      //parseFloat((Math.random()*(lng_max-lng_min) + lng_min).toFixed(5)),
            result : row.result,
            date : row.complete_date.slice(0,10),
            vendor : row.vendor_code,
            info : row,
        }
    )
);

const filterByDateAndVendor = (_list, date_from='', date_to='', vendor='') => _list.filter(
    row => (
        (!date_from || row.date>=date_from) &&
        (!date_to || row.date<=date_to) &&
        (!vendor || row.vendor === vendor)
    )
);

let dots_array = null;
export default function OneDropDis03RegionMap({defultlang, TEST_RESULT_TYPE}) {

    useEffect( () => {

    },[]);
    const centerInit = {
        lat: 37.0,
        lng: 128.0,
    };
    const [center,setcenter] = useState(centerInit);
    const { login_info, date_to_select, vendor_to_select, vendor_to_select2, lang, textOflang, setdefultlang }  = useContext(DistributorContext);
    const token = login_info.token;

    let textlang = textOflang[defultlang];
    let textLangdistributorrRegionmap = textlang.distributor.regionmap;

    let initial_params = {};
    if(login_info.distributor_code){
        if(login_info.distributor_code === 'onedrop'){
            initial_params = {
                "distributor_code": login_info.distributor_code ,
                "start_time": "2020/01/01",
                "end_time": "2022/12/31",
                "periodical_type": "weekly",
                "vendor_code_name" : undefined,
                "entries" : 10000,
                "page" : 0,
                "user_id_contains":  '1drop_'
            }
        }else if(login_info.distributor_code === 'hellas'){
            initial_params = {
                "distributor_code": login_info.distributor_code ,
                "start_time": "2020/01/01",
                "end_time": "2022/12/31",
                "periodical_type": "weekly",
                "vendor_code_name" : undefined,
                "entries" : 10000,
                "page" : 0,
                "user_id_contains": 'Hellas_'
            }
        }else if(login_info.distributor_code === 'biowings'){
            initial_params = {
                "distributor_code": login_info.distributor_code ,
                "start_time": "2020/01/01",
                "end_time": "2022/12/31",
                "periodical_type": "weekly",
                "vendor_code_name" : undefined,
                "entries" : 10000,
                "page" : 0,
                "user_id_contains": 'Biowings_'
            }
        }else{
            initial_params = {
                "distributor_code": login_info.distributor_code ,
                "start_time": "2020/01/01",
                "end_time": "2022/12/31",
                "periodical_type": "weekly",
                "vendor_code_name" : undefined,
                "entries" : 10000,
                "page" : 0,
                "user_id_contains":  login_info.distributor_code + '_'
            }
        }
    }else{
        initial_params = {
            "distributor_code":  undefined,
            "start_time": "2020/01/01",
            "end_time": "2022/12/31",
            "periodical_type": "weekly",
            "vendor_code_name" : undefined,
            "entries" : 10000,
            "page" : 0,

        }
    }


    const [searchOption, setSearchOption] = useState({
        date_from : initial_params.start_time, //"2020/01/01",
        date_to : initial_params.end_time, //"2022/12/31",
        vendor : initial_params.vendor_code_name, //undefined,
    });
    const [params, setParams] = useState(initial_params);

    const [is_analysis_loading, location_map_analysis ] = useLocationMapAnalysis(params, token);
    const { location_map_dot, is_loading_map_dot} = useContext(DistributorContext);

    const analysis_by_zoom = analysisByZoom(location_map_analysis.analysis || {});

    if(dots_array == null){
        dots_array = filterByDateAndVendor( dotArray(location_map_dot.result || []),  params.start_time, params.end_time, params.vendor_code_name);
    }
    useEffect(() => {
        dots_array = filterByDateAndVendor(dotArray(location_map_dot.result || []),
            params.start_time,
            params.end_time,
            params.vendor_code_name)
        return () => { dots_array = filterByDateAndVendor(dotArray(location_map_dot.result || []),
            params.start_time,
            params.end_time,
            params.vendor_code_name);};},[params]);
     const stats_array = analysis_by_zoom || {} ;


    const onSetSearchOption = (vv) => {

        setSearchOption(vv);
        setParams({
            ...params,
            "start_time" : (vv.date_from !== '') ? vv.date_from : initial_params.start_time,
            "end_time" : (vv.date_to !== '') ? vv.date_to : initial_params.end_time ,
            "vendor_code_name" : (vv.vendor) ? vv.vendor : initial_params.vendor_code_name,
        });
        setClickedItem(null);
    };

    const [currentMapState, setCurrentMapState] = useState({});   // 일단은 안쓰고 있음. 디버그용 맵 상태정보 전달 목적.
    const [clickedItem, setClickedItem] = useState();

    const onClickMarker = (selected) => {
        setClickedItem(selected);
    };

    const getCurrentGeoLocation = () => {
        if(navigator.geolocation != null) {
            navigator.geolocation.getCurrentPosition(success, error);
            function success(pos) {
                const crd = pos.coords;
                setcenter({
                    lat: crd.latitude,
                    lng: crd.longitude
                });
            }

            function error(err) {
                if(err.code == 1){
                    alert("위치 확인 권한이 없습니다.")
                }else if(err.code  == 0){
                    alert("알려지지 않는 error")
                }else if(err.code  == 2){
                    alert("위치를 확인 할 수 없습니다.")
                }else if(err.code  == 3){
                    alert("응답 시간 초과")
                }
            }
        }else{
            alert("브라우저가 위치 확인 지원을 하지 않습니다.");
        }
    }


    useEffect(() => {
        getCurrentGeoLocation();
    }, []);

    return (
        <>
            <div style={{paddingBottom:20}}>
                <InputFormSearchDist03
                    date_to_select={date_to_select}
                    vendor_to_select={vendor_to_select}
                    vendor_to_select2 = {vendor_to_select2}
                    handleConfirm = {onSetSearchOption}
                    textLangdistributorrRegionmap={textLangdistributorrRegionmap.serchinputbox}
                />
            </div>
            <div style={{display:'flex', }}>
                <UntilIsLoading is_loading={is_analysis_loading}>
                    <div style={{width:785, display:'inline-box'}}>
                        <div style={{position: "absolute", zIndex: 5, left: "180px", top: "720px"}}><input type={"button"} value ={textLangdistributorrRegionmap.map.button5} className={"currentbutton"}  onClick={getCurrentGeoLocation}/></div>
                        <LocationOnMap is_analysis_loading={is_analysis_loading} dots_array={dots_array || []} stats_array={stats_array} onClickMarker={onClickMarker} center={center} passCurrentMapState={setCurrentMapState} searchOption={searchOption} getCurrentGeoLocation={getCurrentGeoLocation} TEST_RESULT_TYPE2={TEST_RESULT_TYPE}/>
                    </div>
                    <div style={{display:'inline-box',  }}>
                        <CardMap01_MapSide clickedItem={ clickedItem || {}} _params={params} textLangSiteAnalysissearch={textLangdistributorrRegionmap.resultbox.box1} TEST_RESULT_TYPE={TEST_RESULT_TYPE}/>

                    </div>
                </UntilIsLoading>
            </div>
        </>
    )
}