import React from "react";

import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
//import './Posts.module.css'
const Posts = ({ posts, loading,setarticleSeqSelectedFun,setarticleSeqSelected,setarticletypeSelected,login_info,totalPosts,currentPage,postsPerPage,setarticleindexSelected }) => {

    return (
        <>
            {loading && <div> loading... </div>}
            <Container style={{width:'1080px',height:"480px",  position:"relative",'margin-top': '50px'}}>
            <Table bordered hover>
                <thead >
                <tr>
                    <th>번호</th>
                    <th >제목</th>
                    <th >글쓴이</th>
                    <th>날짜</th>
                </tr>
                </thead>
                <tbody>
                {
                    posts.map((list,index) =>
                        <tr>
                            <td width={'100px'}><div onClick={() => {setarticleSeqSelectedFun(setarticleSeqSelected, list.seq, setarticleindexSelected, totalPosts-(currentPage-1)*postsPerPage-index); setarticletypeSelected('OnedropCsArticleBoardDetail');}}>{login_info.site_key == 'onedrop' ? list.writer +"-"+ list.seq  : totalPosts-(currentPage-1)*postsPerPage-index}</div></td>
                            <td width={'500px'}><div onClick={() => {setarticleSeqSelectedFun(setarticleSeqSelected, list.seq, setarticleindexSelected, totalPosts-(currentPage-1)*postsPerPage-index); setarticletypeSelected('OnedropCsArticleBoardDetail');}}>{list.title}</div></td>
                            <td width={'150px'}><div onClick={() => {setarticleSeqSelectedFun(setarticleSeqSelected, list.seq, setarticleindexSelected, totalPosts-(currentPage-1)*postsPerPage-index); setarticletypeSelected('OnedropCsArticleBoardDetail');}}>{list.writer}</div></td>
                            <td width={'250px'}><div onClick={() => {setarticleSeqSelectedFun(setarticleSeqSelected, list.seq, setarticleindexSelected, totalPosts-(currentPage-1)*postsPerPage-index); setarticletypeSelected('OnedropCsArticleBoardDetail');}}>{new Date(list.createdate).toLocaleDateString("ko-KR") +" "+ new Date(list.createdate).toLocaleTimeString()}</div></td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
            </Container>
        </>
    );
};
export default Posts;