import React from 'react';

//import { Typography } from '@mui/material'
import CardBasic  from  '_components_public/CardBasic';
 
import BarTiny from "_components_chart/BarTiny";


const listByVendor = (by_vendor) => {
    if (!by_vendor || Object.keys(by_vendor).length===0) {
        return [];
    };
    return Object.entries(by_vendor).map( ([kk,vv]) => (
        {
            idx : kk,
            label: vv.name.slice(0,4), 
            cnt :  vv.use_count,
            name : vv.name,        
        }
        ));
};


export default function CardDist04_UseBySeller({location_analysis, sumOFFieldAnalysis, textLangAnalysisresultbox}) {

    const by_vendor = location_analysis.vendor;
    const use_by_vendor = React.useMemo(()=>listByVendor( by_vendor ), [by_vendor]);

    const CardBody = (sumOFFieldAnalysis != 0) ?
        (
        <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            }}>        
        <BarTiny data={use_by_vendor}/>
        </div>)
        : (<div style={{padding:'0 20px 0 20px'}}>
            <div style={{width: "420px", height: "260px", "vertical-align": "middle", display: "table-cell", "text-align": "center"}}>{textLangAnalysisresultbox.error1}</div>
        </div>)
    

    return (
        <>
        <CardBasic card_title={textLangAnalysisresultbox.title} card_body={CardBody} style={{position: 'relative', width:515, height:310}}/>
        </>

    )
}