import {useContext, useEffect, useLayoutEffect, useState} from 'react';

import {
    useGetBoardDetail,
} from "../__api/requestOneDropData";
import axios from "axios";
import {ArticleContext} from "./onedropCSArticleBoardMain";
import {SiteContext} from "../__context_api/SiteContext";

import Pagination from "./Pagination";
import Comments from "./Comments";

import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
export default function ComponentArticleDetail(seq) {
    const { articletypeSelected, setarticletypeSelected } = useContext(ArticleContext);
    const { articleSeqSelected, setarticleSeqSelected } = useContext(ArticleContext);
    const { articleindexSelected, setarticleindexSelected } = useContext(ArticleContext);
    const { login_info, setlogin_info } = useContext(SiteContext);
    //let BoardList = null;

    const [boardDetail,setboardDetail] = useState([]);
    const [commentList,setcommentList] = useState([]);
    const [reload,setreload] = useState(false);
    const [BoardDetail, setBoardDetail,is_loading, setis_loading] = useGetBoardDetail(articleSeqSelected,login_info);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(4);
      function isEmptyObj(obj)  {
        if(typeof obj === Object){
            if(obj.constructor === Object
                && Object.keys(obj).length === 0)  {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        if (isEmptyObj(BoardDetail) != true) {

        };

        const url = "https://localhost:8000/homekit/admin/article/detail";
        const params = {
            "site_key": login_info.site_key,
            "seq": articleSeqSelected

        };
        const headers = {
            //'host': host,
            //'Cookie': token,
            "application": "json"

        };
        let result = axios.post(url, params, {withCredentials: true}).then(response =>{

            if(response.status == 200){

                setcommentList(response.data.comment);
            }
             }
         ).catch(err => {

        })

        setboardDetail(BoardDetail.article);
        setcommentList(BoardDetail.comment);


    },[ is_loading,reload]);






    /* 새로 추가한 부분 */
    const indexOfLast = currentPage * postsPerPage;
    const indexOfFirst = indexOfLast - postsPerPage;

    const currentPosts = (commentList) => {
        let currentPosts = 0;
        currentPosts = commentList.slice(indexOfFirst, indexOfLast);
        return currentPosts;
    };

    if (boardDetail != undefined && boardDetail.length > 0) {

        return (

            <>

                <Container>
                    <Table bordered  style={{"width": "500px"}}>
                        <thead>
                        <tr>
                            <th >번호</th>
                            <th >{login_info.site_key == 'onedrop' ? boardDetail[0].writer +"-"+ boardDetail[0].seq  : articleindexSelected}</th>
                            <th >작성자</th>
                            <th >{boardDetail[0].writer}</th>
                            <th >날짜</th>
                            <th >{new Date(boardDetail[0].createdate).toLocaleDateString("ko-KR") +" "+ new Date(boardDetail[0].createdate).toLocaleTimeString()}</th>
                        </tr>
                        <tr>
                            <th height={"5px"} >제목</th>
                            <th height={"5px"} colSpan='6' ><textarea style={{'width':'100%', "height":'35px', resize: "none"}} value={boardDetail[0].title}></textarea></th>


                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td height={'350px'} style={{textAlign:"center", "vertical-align": 'middle'}}>내용</td>
                            <td colSpan="7" height={'350px'} style={{textAlign:"center", "vertical-align": 'middle'}}><textarea style={{'width':'100%', "height":'100%', resize: "none"}} value={boardDetail[0].content}></textarea></td>
                        </tr>

                        </tbody>
                    </Table>
                            <Comments posts={currentPosts(commentList)} loading={is_loading}
                                      delComment={delComment}
                                   setarticleSeqSelected={setarticleSeqSelected}
                                   setarticletypeSelected={setarticletypeSelected}
                                   login_info={login_info}
                                   totalPosts={commentList.length}
                                   currentPage={currentPage}
                                   postsPerPage={postsPerPage}
                                      articleSeqSelected={articleSeqSelected}
                                   setarticleindexSelected={setarticleindexSelected}
                                      boardDetail={boardDetail}
                                      setBoardDetail={setBoardDetail}
                                      setloading={setreload}
                                      setreload={setreload}
                                      reload={reload}
                                      insertComment={insertComment}
                            ></Comments>
                            <Pagination style={{display: "inline-flex"}}
                            postsPerPage={postsPerPage}
                            totalPosts={commentList.length}
                            paginate={setCurrentPage}
                            ></Pagination>

                    <Table bordered size={"sm"} >
                        <tbody>
                        <tr>
                            <td  colSpan="7" height={'100px'} width={'550px'}><textarea style={{'width':'100%', "height":'100%', resize: "none"}} id={"insertcommentcontent"} placeholder={"댓글을 입력하세요"} ></textarea></td>
                            <td style={{textAlign:"center",     'vertical-align': 'middle'}}><input type="button" value={"입력"} maxLength={600} style={{textAlign:"center",     'vertical-align': 'middle'}} className={"btn btn-default"} onClick={() => {insertComment( boardDetail[0].seq, setarticletypeSelected,articleSeqSelected, login_info,setreload,reload,1);}}></input></td>
                        </tr>
                        </tbody>
                    </Table>


                <input type = "button" value = "리스트로" className={"btn btn-default pull-right"} onClick={() =>{setarticletypeSelected('OnedropCSArticleBoard')}}></input>
                <input type = "button" value = "수정" className={"btn btn-default pull-right"} onClick={() =>{setarticleSeqSelected(boardDetail[0].seq); setarticletypeSelected('OnedropCSArticleBoardModify');}}></input>
                <input type = "button" value = "삭제" className={"btn btn-default pull-right"} onClick={() =>{getdelete(boardDetail[0].seq, setarticletypeSelected)}}></input>

                </Container>
                </>

        )
    }else {
        return null;
    }
}




async function insertComment(seq, setarticletypeSelected,articleSeqSelected, login_info,setreload,reload,priority ){
    let articleComment = document.getElementById('insertcommentcontent').value;


    if (articleComment == "") {
        alert("댓글 입력 창에 내용을 입력해주세요");
        return false;
    }
    if (articleComment.length >= 600) {
        alert("댓글 입력 창에 내용이 너무 깁니다.");
        return false;
    }
    const params = {
        'comment' : articleComment,
        'seq':seq,
        'writer' : login_info.site_key,
        'priority':priority
    }
    const headers ={
        'Content-Type': 'application/json'
    }

    const URL = process.env.REACT_APP_SITEDATA_ARTICLE_COMMENT_WRITE || '/homekit/admin/comment/write';
    let responseCommentData = null;
    try{
        responseCommentData = await axios.post(
            URL, params, { withCredentials: true});

        if(responseCommentData.status == 200 && responseCommentData.data.error != '' || responseCommentData.error != null){


        }else{
            alert('댓글이 실패하였습니다. error code = \n');
        }
    }catch{

    }

    if(responseCommentData.status == 200 && responseCommentData.data.error != '' || responseCommentData.error != null){
        setreloadFun(setreload,reload);
    }
}




function setreloadFun(setreload,reload){

   if(reload){
        setreload(false);
   }else{
        setreload(true);
   }

}

const CommentList = (commentList) => {


    return
        {
            commentList.map((list) =>

            <tr>
                <td >댓글</td>
               <td  colSpan="11"><textarea value={list.comment}></textarea></td>
            </tr>
            )
        }


}

async function delComment(commentseq,seq,setarticletypeSelected,articleSeqSelected,setreload,reload){

    if(window.confirm("정말 삭제 하시겠습니까?")){


        const URL = process.env.REACT_APP_SITEDATA_ARTICLE_COMMENT_DELETE || 'testsrv.1drop.co.kr';
        const PARAM = {
            "commentseq" : commentseq
        }
        let deleteResult = null;
        try{
            deleteResult = await axios.post(URL,PARAM,{withCredentials: true});

            if(deleteResult.status === 200){
                deleteResult = deleteResult.data;
                if(deleteResult == undefined){alert('삭제할 데이터가 없습니다.');}
                if(deleteResult.deleteResult === 1){
                    alert('삭제 되었습니다.');
                    // articleSeqSelected(seq);
                    // setarticletypeSelected('OnedropCSArticledetail');
                    setreloadFun(setreload,reload);
                }
            }else{
                alert('삭제할 데이터가 없습니다.');
            }



        }catch{
            alert('삭제가 되지 않았습니다.');
        }

    }else{

        return false;
    }

}


 async function getdelete(seqParam, setarticletypeSelected){

    if(window.confirm("정말 삭제 하시겠습니까?")){
        const URL = process.env.REACT_APP_SITEDATA_ARTICLE_DELETE || 'testsrv.1drop.co.kr';
        const PARAM = {
            "seq" : seqParam
        }
        let deleteResult = null;
        try{
            deleteResult = await axios.post(URL,PARAM,{withCredentials: true});

            if(deleteResult.status === 200){
                deleteResult = deleteResult.data;
                if(deleteResult == undefined){alert('삭제할 데이터가 없습니다.');}
                if(deleteResult.deleteResult === 1){
                    alert('삭제 되었습니다.');
                    setarticletypeSelected('OnedropCSArticleBoard');}
                }else{
                    alert('삭제할 데이터가 없습니다.');
                }
        }catch{
            alert('삭제가 되지 않았습니다.');
        }
    }else{

        return false;
    }

}