
import axios from 'axios';

export async function requestLogOut( remember_me=true) {
    window.localStorage.removeItem('onedrop');
   // const URL = 'https://testsrv.1drop.co.kr/homekit/admin/api/logout';

    let URL = process.env.REACT_APP_LOGOUT_URL || '/homekit/admin/api/logout';

    //const URL = process.env.REACT_APP_LOGOUT_URL + '/homekit/admin/api/logout';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/logout';
    const params = {};
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Request-Method':"post",
        'Access-Control-Request-Headers':"*"
    };    
    try {
       let res = await axios(
        {
            method: 'get',            
            url : URL,
            data : params    ,
            headers: headers,
            withCredentials : true,
        }
        );
        if(res.status === 200){
            // test for status you want, etc

        }    


        return res.data
    }
    catch (err) {
        console.error(err);
    }
};