
import axios from 'axios';


// <script async defer src="https://apis.google.com/js/api.js" onLoad="gapiLoaded()"></script>
// <script async defer src="https://accounts.google.com/gsi/client" onLoad="gisLoaded()"></script>

// const script = document.createElement("script");
// script.src = "https://apis.google.com/js/api.js";
// script.async = true;
// script.defer = true;
// script.onLoad = gapiLoaded();
// document.body.appendChild(script);
//
// const script2 = document.createElement("script");
// script2.src = "https://accounts.google.com/gsi/client";
// script2.async = true;
// script2.defer = true;
// script2.onLoad = gisLoaded();
// document.body.appendChild(script);
//
//
// // TODO(developer): Set to client ID and API key from the Developer Console
// const CLIENT_ID = '588490015034-p6l1agitdf7r8jd113e3jlc2srvq5rfl.apps.googleusercontent.com';
// const API_KEY = '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';
//
// // Discovery doc URL for APIs used by the quickstart
// const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest';
//
// // Authorization scopes required by the API; multiple scopes can be
// // included, separated by spaces.
// const SCOPES = 'https://www.googleapis.com/auth/gmail.readonly';
//
// let tokenClient;
// let gapiInited = false;
// let gisInited = false;
//
// document.getElementById('authorize_button').style.visibility = 'hidden';
// document.getElementById('signout_button').style.visibility = 'hidden';
//
// /**
//  * Callback after api.js is loaded.
//  */
// function gapiLoaded() {
//     gapi.load('client', initializeGapiClient);
// }
//
// /**
//  * Callback after the API client is loaded. Loads the
//  * discovery doc to initialize the API.
//  */
// async function initializeGapiClient() {
//     await gapi.client.init({
//         apiKey: API_KEY,
//         discoveryDocs: [DISCOVERY_DOC],
//     });
//     gapiInited = true;
//     maybeEnableButtons();
// }
//
// /**
//  * Callback after Google Identity Services are loaded.
//  */
// function gisLoaded() {
//     tokenClient = google.accounts.oauth2.initTokenClient({
//         client_id: CLIENT_ID,
//         scope: SCOPES,
//         callback: '', // defined later
//     });
//     gisInited = true;
//     maybeEnableButtons();
// }
//
// /**
//  * Enables user interaction after all libraries are loaded.
//  */
// function maybeEnableButtons() {
//     if (gapiInited && gisInited) {
//         document.getElementById('authorize_button').style.visibility = 'visible';
//     }
// }
//
// /**
//  *  Sign in the user upon button click.
//  */
// function handleAuthClick() {
//     tokenClient.callback = async (resp) => {
//         if (resp.error !== undefined) {
//             throw (resp);
//         }
//         document.getElementById('signout_button').style.visibility = 'visible';
//         document.getElementById('authorize_button').innerText = 'Refresh';
//         await listLabels();
//     };
//
//     if (gapi.client.getToken() === null) {
//         // Prompt the user to select a Google Account and ask for consent to share their data
//         // when establishing a new session.
//         tokenClient.requestAccessToken({prompt: 'consent'});
//     } else {
//         // Skip display of account chooser and consent dialog for an existing session.
//         tokenClient.requestAccessToken({prompt: ''});
//     }
// }
//
// /**
//  *  Sign out the user upon button click.
//  */
// function handleSignoutClick() {
//     const token = gapi.client.getToken();
//     if (token !== null) {
//         google.accounts.oauth2.revoke(token.access_token);
//         gapi.client.setToken('');
//         document.getElementById('content').innerText = '';
//         document.getElementById('authorize_button').innerText = 'Authorize';
//         document.getElementById('signout_button').style.visibility = 'hidden';
//     }
// }
//
// /**
//  * Print all Labels in the authorized user's inbox. If no labels
//  * are found an appropriate message is printed.
//  */
// async function listLabels() {
//     let response;
//     try {
//         response = await gapi.client.gmail.users.labels.list({
//             'userId': 'me',
//         });
//     } catch (err) {
//         document.getElementById('content').innerText = err.message;
//         return;
//     }
//     const labels = response.result.labels;
//     if (!labels || labels.length == 0) {
//         document.getElementById('content').innerText = 'No labels found.';
//         return;
//     }
//     // Flatten to string to display
//     const output = labels.reduce(
//         (str, label) => `${str}${label.name}\n`,
//         'Labels:\n');
//     document.getElementById('content').innerText = output;
// }
//
//
//
//
//
//




export const requestDeleteTableRowForTestCenter = async(row) => {  //선택된 테이블 Row 삭제
    const headers = {
        'Content-Type': 'application/json',
    };

    let result = null;
    try {
        result =  await axios.delete('https://jsonplaceholder.typicode.com/posts/1',//임시 테스트 주소
           // axios.delete('https://jsonplaceholder.typicode.com/possdts/1',//임시 테스트 주소
            { headers,
            data: {
                date: row.date,
                test_time: row.test_time,
                staff_code: row.staff_code
            },
            withCredentials: true,
        })/*.then(function (response) {
            // handle success

        })*/
    }catch(error){
        console.error(error);

    }

    return result;
}
export const requestDeleteTableRowForMemberOfClinomic = async(row) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    let result = null;
        try{
        result =  await axios.delete('https://jsonplaceholder.typicode.com/posts/1',//임시 테스트 주소
        // axios.delete('https://jsonplaceholder.typicode.com/possdts/1',//임시 테스트 주소
        { headers,
            data: {
                name: row.name,
                employee_code: row.employee_code
            },
            withCredentials: true,
        })



        /*.then(function (response) {
        // handle success

        return response;*/

    }catch(error) {
        // handle error


    }
    return result;
}






export const requestSendSmsFuc = async (row,table_title) =>{


    //QUMxYTBlODZjODdkZjE2ZGY2MDhmODlhNjBjNzU3Yjc0Njo4MTc2MmNhM2U4ZDI0ZWM2NjVmMjllNWNmZWY0MWE0NA==
    const headers = {'Content-Type':'application/x-www-form-urlencoded',
            "Authorization": "Basic QUMxYTBlODZjODdkZjE2ZGY2MDhmODlhNjBjNzU3Yjc0Njo4MTc2MmNhM2U4ZDI0ZWM2NjVmMjllNWNmZWY0MWE0NA=="
        };


    if(row.phone == undefined  || row.phone == null){
        if(table_title == "Employee Inspection Status"){
            alert("There is no resistered Phone Number.");
        }else{
            alert("등록된 phone Number가 없습니다.");
        }
        return false;
    }
    let params = new URLSearchParams();
    params.append('To', '+82' + String(row.phone)); //한국


    //params.append('To', row.phonenum);//추후 개발하면 주석해제 후 수정
    if(table_title == "Employee Inspection Status"){
        //en
        params.append('Body', "ID: " + row.name + "\n"+"Staff Code: "+row.employee_code+"\n"+ "Inspection Result: " + row.recent_test_result);
    }else{
        //ko
        params.append('Body', "ID: " + row.name + "\n"+"사번: "+row.employee_code+"\n"+ "검사결과: " + row.recent_test_result);
    }

    params.append('MessagingServiceSid', 'MG0fd4cc7645efb90bc1982a62022476c3');

    let result = null;
    let url = "https://api.twilio.com/2010-04-01/Accounts/AC1a0e86c87df16df608f89a60c757b746/Messages.json";


    //url = process.env.REACT_APP_SENDESMS||"/2010-04-01/Accounts/AC1a0e86c87df16df608f89a60c757b746/Messages.json";
        try {

            // result = await axios.post(
            //     // "https://api.twilio.com/2010-04-01/Accounts/AC1a0e86c87df16df608f89a60c757b746/Messages.json",
            //     url,
            //     // "To:'+8201097851715', Body:'This is test sms2', MessagingServiceSid:MG0fd4cc7645efb90bc1982a62022476c3"
            //     // "To:'+8201046517359', Body:'This is test sms', MessagingServiceSid:MG0fd4cc7645efb90bc1982a62022476c3"
            //     params,
            //     headers,
            //
            //
            // )

            let res = await axios(
                {
                    method: 'post',
                    url : url,
                    data : params    ,
                    headers: headers,
                    withCredentials : false,
                }
            );





            if(res.status === 201){
                // test for status you want, etc

                if(table_title == "Employee Inspection Status"){
                    alert('SMS was sended to ' +  row.name );
                }else{
                    alert('SMS가 ' +  row.name + '님께 \n 발송되었습니다.');
                }

            }else{
                if(table_title == "Employee Inspection Status"){
                    alert('SMS was not sended to ' +  row.name );
                }else{
                    alert('SMS가 ' +  row.name + '님께 \n 발송되지 못하였습니다.');
                }


            }
            //
            //     .then(function (response) {
            //     // handle success
            //     if(table_title == "Employee Inspection Status"){
            //         alert('SMS was sended to ' +  row.name );
            //     }else{
            //         alert('SMS가 ' +  row.name + '님께 \n 발송되었습니다.');
            //     }
            //
            //
            //
            // }).catch(function(error){
            //
            //
            // })


        }catch(error){

            if(table_title == "Employee Inspection Status"){
                alert('SMS was not sended to ' +  row.name );
            }else{
                alert('SMS가 ' +  row.name + '님께 \n발송되지 못하였습니다.');
            }

        };

};
/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
function oauthSignIn() {
    // Google's OAuth 2.0 endpoint for requesting an access token
    var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {'client_id': '588490015034-p6l1agitdf7r8jd113e3jlc2srvq5rfl.apps.googleusercontent.com',
        'redirect_uri': 'https://localhost:3000/emailsite',
        //'response_type': 'token',
        'scope': 'https://www.googleapis.com/auth/gmail.send',
        "response_type":'code',
        'include_granted_scopes': 'true',
        'state': 'state_parameter_passthrough_value'};
        //'state': 'pass-through value'};
    //state_parameter_passthrough_value
    // Add form parameters as hidden input values.
    for (var p in params) {
        var input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
}


export const sendEmailFun = async(row,table_title) => {


    //QUMxYTBlODZjODdkZjE2ZGY2MDhmODlhNjBjNzU3Yjc0Njo4MTc2MmNhM2U4ZDI0ZWM2NjVmMjllNWNmZWY0MWE0NA==
   /* const headers = {"Authorization": /!*Bearer <<YOUR_API_KEY>>*!/",'Content-Type:' application/json'};*/


    //let params = new URLSearchParams();
    /*params.append('To', 'xenexes@naver.com');

*/
    if(row.email == undefined || row.email == "" || row.email == null){

        if(table_title == "Employee Inspection Status"){
            alert("There is no resistered email.");
        }else{
            alert("등록된 email이 없습니다.");
        }
        return false;
    }
    let params ;
    if(table_title == "Employee Inspection Status"){
        alert("This Function isn't avilable now");
        return false;
        //en

        // params =
        // {
        //     "id": "yjgim",
        //     "threadId": null,
        //     "labelIds": [
        //     null
        // ],
        //     "snippet": "test email 입니다",
        //     "historyId": null,
        //     "internalDate": null,
        //     "payload": "test email 입니다",
        //     "sizeEstimate": 400,
        //     "raw": null
        // }




    }else{
        alert("지금 지원 하지 않는 기능입니다.");
        return false;


        //ko

        // params =
        //     {
        //         raw :"67CY6rCR7Iq164uI64ukIO2FjOyKpO2KuOyeheuLiOuLpC4",
        //         headers:{
        //             "To":"xenexes@naver.com",
        //             "name":"youngjugim",
        //             "value":"xenexes@naver.com"
        //
        //         }
        //     }


    }

    let result = null;
    // const headers =  {
    //             Authorization:"Bearer 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",
    //             "Content-Type":"application/json"
    // }

    const header = {
        "key":"AIzaSyCbsWiNBP-pJ4xwqcezAnphpc3ihc1Dpow",
        "name": "[1drop]",
        "value": "yjgim@1drop.co.kr",
        'Access-Control-Request-Method':"post",
        'Access-Control-Request-Headers':"Content-Type",
        'Authorization': "Bearer " //+ accesstoken
        //'Authorization': "Bearer " //+ accesstoken
    }


    //let URL = "https://api.sendgrid.com/v3/mail/send";
    let URL = "https://gmail.googleapis.com/gmail/v1/users/yjgim@1drop.co.kr/messages/send";

    let res;
    try {

        res = await axios(
        {
            method: 'post',
            url : URL,
            data : params    ,
            headers: header,

        }
        );
       axios.defaults.withCredentials = true;


        if(res.status === 200){
            // test for status you want, etc

            if(table_title == "Employee Inspection Status"){
                alert('Email was sended to ' +  row.name );
            }else{
                alert('이메일이 ' +  row.name + '님께 \n발송되었습니다.');
            }

        }

        return res.data
    }

    catch(error){

    };
    alert(res);

}
