import {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {Box, TextField, TextFieldwithBG,  Grid, FormControl, InputLabel, Select, Menu, MenuItem } from "__ui_components/UIComponents";

import SelectItem from "_components_public/SelectItem";
import {useGetBoardList, useFieldTestCenter} from "../__api/requestOneDropData";
import axios from "axios";
import {SiteContext} from "../__context_api/SiteContext";
import {ArticleContext} from "./onedropCSArticleBoardMain";
//import "/node_modules/bootstrap/dist/css/bootstrap.css"
//import styles from './onedropCSArticleBoardWrite.module.css';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';

export default function ComponentArticleWrite() {
    const { articletypeSelected, setarticletypeSelected } = useContext(ArticleContext);
    const { articleSeqSelected, setarticleSeqSelected } = useContext(ArticleContext);

    const { login_info, setlogin_info} = useContext(SiteContext);

    function isEmptyObj(obj)  {
        if(typeof obj === Object){
            if(obj.constructor === Object
                && Object.keys(obj).length === 0)  {
                return true;
            }
        }
        return false;
    }

        return (

            <>

                <Table bordered={true} style={{'width' : '1000px'}}>
                        <thead>
                        <tr>
                            <th>제목</th>
                            <th width={'500px'} height={'100px'} colSpan="5" ><textarea style={{'width':'100%','height':'100%' ,resise:"none"}} id={'titleinput'} name={'titleinput'}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td >내용</td>
                            <td width={'500px'} height={"500px"}  colSpan="5" ><textarea  style={{'width':'100%', 'height':'100%',resise:"none"}} id = {'contentinput'} name={'contentinput'}/></td>
                        </tr>
                        </tbody>
                    </Table>

                <Button value = "리스트로" onClick={() =>{setarticletypeSelected('OnedropCSArticleBoard')}}></Button>
                <Button value = "글쓰기"  onClick={() => submitArticleData(login_info, setarticletypeSelected)}></Button>
             </>

    )

}


async function submitArticleData(login_info, setarticletypeSelected) {
    let articleTitle = document.getElementsByName('titleinput');
    let articleContent = document.getElementsByName('contentinput');
    articleTitle = articleTitle[0].value;
    articleContent = articleContent[0].value;

    if (articleTitle == "") {
        alert("제목 창에 내용을 입력해주세요");
        return false;
    }
    if (articleTitle.length > 200) {
        alert("제목 창에 내용이 너무 깁니다.");
        return false;
    }
    
    if (articleContent == "") {
        alert("내용 창에 내용을 입력해주세요");
        return false;
    }
    if (articleContent.length > 1000) {
        alert("내용 입력 창에 내용이 너무 깁니다.");
        return false;
    }


    const params = {
        'title' : articleTitle,
        'content' : articleContent,
        'writer' : login_info.site_key
    }
    const headers ={
        'Content-Type': 'application/json'
    }

    const URL = process.env.REACT_APP_SITEDATA_ARTICLE_WRITE || '/homekit/admin/article/write';
    let responseArticleData = null;
    try{
        responseArticleData = await axios.post(
            URL, params, { withCredentials: true});

        responseArticleData = responseArticleData.data;
        if(responseArticleData.error != '' || responseArticleData.error != null)
            setarticletypeSelected('OnedropCSArticleBoard');
        else{
            alert('글쓰기가 실패하였습니다. error code = \n', responseArticleData.error);
        }
    }catch{

    }

}