import {useMemo} from 'react';
//import { Typography , Stack} from '@mui/material'
import CardBasic  from  '_components_public/CardBasic';

import { subtotalOfAllTestResultType } from "__lib/cTools";

import BarTiny from "_components_chart/BarTiny";


const testCountByDepartment = (list_by_department) => {
    return list_by_department.map(
        (row, index) => (
            {
                idx : row.idx,
                label : row.idx,
                name : row.name,
                description : row.name,                
                cnt : subtotalOfAllTestResultType(row),
            }
        ));
};


export default function CardUser02_UseCount( {field_analysis, list_by_department, by_department, sumOFFieldAnalysis,textLangSiteAnalysisCard}) {
    const test_count_by_department =  useMemo(()=>testCountByDepartment(list_by_department), [list_by_department]);

    const CardBody = (sumOFFieldAnalysis != 0) ? (
        <div style={{padding:'0 20px 0 20px'}}>        
            <div
            style={{
                position: 'absolute',
                bottom: 0,
                left: 15,
                }}
            >
                <BarTiny width={400}  data={test_count_by_department} isAnimationActive={false}/>
            </div>
        </div>    
    ) :
        (<div style={{padding:'0 20px 0 20px'}}>
            <div style={{width: "420px", height: "260px", "vertical-align": "middle", display: "table-cell", "text-align": "center"}}>{textLangSiteAnalysisCard.error1}</div>
    </div>)

    return (
        <>
        <CardBasic card_title={textLangSiteAnalysisCard.title} card_body={CardBody} style={{position: 'relative', width:440, height:310}}/>
        </>
    )
}