

import {
    useRequestAPI2,
    useRequestAPI2forDetail,
    useRequestAPI2ForDetail,
    useRequestAPI2forDetail2
} from "__api/useRequestAPI2";


export function useFieldDepartment  (
    params,
    token='access_token=b718f418d1ac4a49aa9b7201a35ed224', 
   host= process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr',
    //  host='homekit.1drop.co.kr',
    _render_key='',
    ) {

    const parser = ([is_loading, rtn]) => {        
        return  [is_loading, rtn ];
    };



    const render_key = _render_key || `${params.site_key}${params.start_time}${params.end_time}${params.periodical_type}` ;
    let URL = process.env.REACT_APP_SITEDATA_DEPARTMENT || '/homekit/admin/api/field/department';

    //const URL =  'https://testsrv.1drop.co.kr/homekit/admin/api/field/department';
     //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/field/department';
    const headers = {
        'Access-Control-Request-Method':"post",
        'Access-Control-Request-Headers':"*"
    };
    return parser(
        useRequestAPI2(
            URL, // url
            params, // params
            headers,
            render_key
            ));
    };



export function useFieldAnalysis (
    params,
    token='access_token=b718f418d1ac4a49aa9b7201a35ed224',
    host= process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr',
    //  host='homekit.1drop.co.kr',
    _render_key='',
    ) {

    const parser = ([is_loading, rtn]) => {        
        return  [is_loading, rtn ];
    };

    const render_key = _render_key || `${params.site_key}${params.start_time}${params.end_time}${params.periodical_type}${params.department_code}` ;
    //const URL = 'https://testsrv.1drop.co.kr/homekit/admin/api/field/analysis';
    //const URL = '/homekit/admin/api/field/analysis';

    let URL = process.env.REACT_APP_SITEDATA_ANALYSIS || '/homekit/admin/api/field/analysis';

    //const URL = process.env.REACT_APP_SITEDATA_ANALYSIS + '/homekit/admin/api/field/analysis';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/field/analysis';
    const headers = {
        'Access-Control-Request-Method':"post",
        'Access-Control-Request-Headers':"*"
    };
    return parser(
        useRequestAPI2(
            URL, // url
            params, // params
            headers,
            render_key
            ));
    };

export function useFieldTestCenter (params,
    token='access_token=b718f418d1ac4a49aa9b7201a35ed224',
    host= process.env.REACT_APP_SITEDATA_TESTCENTER|| 'testsrv.1drop.co.kr',
    //   host='homekit.1drop.co.kr',

    ) {

    const parser = ([is_loading, rtn]) => {        
        return  [is_loading, rtn ];
    };




    const URL = process.env.REACT_APP_SITEDATA_TESTCENTER || '/homekit/admin/api/field/testcenter';

    //const URL = 'https://testsrv.1drop.co.kr/homekit/admin/api/field/testcenter';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/field/testcenter';
    const headers = {
        //'host': host,
        //'Cookie': token,
    };
    return parser(
        useRequestAPI2(
            URL, // url
            params, // params
            headers
            ));
};

export function useFieldStaff  (params,
    token='access_token=b718f418d1ac4a49aa9b7201a35ed224',
    host= process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr',
    //  host='homekit.1drop.co.kr',

    )  {

    const parser = ([is_loading, rtn]) => {        
        return  [is_loading, rtn ];
    };

    //const URL = 'https://testsrv.1drop.co.kr/homekit/admin/api/field/staff';

    let URL = process.env.REACT_APP_SITEDATA_STAFF || '/homekit/admin/api/field/staff';


    const headers = {
        'Access-Control-Request-Method':"post",
        'Access-Control-Request-Headers':"*"
    };
    return parser(
        useRequestAPI2(
            URL, // url
            params, // params
            headers
            ));
};

// export async function  getBoardList() {
export function useGetBoardList() {

    const params = {
        "site_key": undefined,
        "searchkeyword": "",
        "page": 1,
        "entries": 100,
        "maxentries": 100
    };
    const token = 'access_token=b718f418d1ac4a49aa9b7201a35ed224';
    const host = process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr';
    //  host='homekit.1drop.co.kr',


    let URL = process.env.REACT_APP_SITEDATA_ARTICLE_LIST || '/article/list';

    //const URL = process.env.REACT_APP_SITEDATA_ANALYSIS + '/homekit/admin/api/field/analysis';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/field/analysis';
     const headers = {
         //'host': host,

         //'Cookie': token,
         "application": "json"
     };


    const parser = ([is_loading, rtn]) => {

        return [is_loading, rtn ];
    };
    return parser(
        useRequestAPI2(
            URL, // url
            params, // params
            headers
        ));
}export function useGetBoardDelete(detail) {

    const params = {
        "site_key": "onedrop",
        "seq": detail.seq
    };
    const token = 'access_token=b718f418d1ac4a49aa9b7201a35ed224';
    const host = process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr';
    //  host='homekit.1drop.co.kr',


    let URL = process.env.REACT_APP_SITEDATA_ARTICLE_DELETE || '/article/delete';

    // //const URL = process.env.REACT_APP_SITEDATA_ANALYSIS + '/homekit/admin/api/field/analysis';
    // //   const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/field/analysis';
     const headers = {
         'Access-Control-Request-Method':"post",
         'Access-Control-Request-Headers':"*"
     };


    const parser = ([is_loading, rtn]) => {

        return [is_loading, rtn ];
    };
    return parser(
        useRequestAPI2(
            URL, // url
            params, // params
            headers
        ));
}

export function useGetBoardDetail(seq,login_info,reload) {

    const params = {
        "site_key": login_info.site_key,
        "seq": seq

    };
    const token = 'access_token=b718f418d1ac4a49aa9b7201a35ed224',
        host = process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr';
    //  host='homekit.1drop.co.kr',


    let URL = process.env.REACT_APP_SITEDATA_ARTICLE_DETAIL || '/article/detail';

    const headers = {
        //'host': host,
        //'Cookie': token,
        "application": "json"

    };

    let parser = ([rtn, setRtn,isLoading, setIsLoading]) => {

            return [rtn, setRtn,isLoading, setIsLoading];
        };

    return parser(
        useRequestAPI2forDetail2(
            URL, // url
            params, // params
            headers,
            reload
        ));
}
export function useGetBoardDetailForModify(seq,login_info) {
    const params = {
        "site_key": login_info.site_key,
        "seq": seq

    };
    const token = 'access_token=b718f418d1ac4a49aa9b7201a35ed224',
        host = process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr';
    //  host='homekit.1drop.co.kr',


    let URL = process.env.REACT_APP_SITEDATA_ARTICLE_DETAIL || '/article/detail';


    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/field/analysis';
    const headers = {
        //'host': host,
        //'Cookie': token,
        "Content-Type": "application/json"

    };

    const parser = ([is_loading, rtn]) => {

        return [is_loading, rtn];
    };
    return parser(
        useRequestAPI2ForDetail(
            URL, // url
            params, // params
            headers
        ));

}

export function useGetBoardCommentList(seq,login_info) {
    const params = {
        "site_key": login_info.site_key,
        "seq": seq

    };
    const token = 'access_token=b718f418d1ac4a49aa9b7201a35ed224',
        host = process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr';
    //  host='homekit.1drop.co.kr',


    let URL = process.env.REACT_APP_SITEDATA_COMMENT_DETAIL || '/article/detail';


    const headers = {
        //'host': host,
        //'Cookie': token,
        "Content-Type": "application/json"

    };

    const parser = ([is_loading, rtn]) => {

        return [is_loading, rtn];
    };
    return parser(
        useRequestAPI2ForDetail(
            URL, // url
            params, // params
            headers
        ));


}


export function useGetBoardCommentDetail(seq,login_info) {
    const params = {
        "site_key": login_info.site_key,
        "seq": seq

    };
    const token = 'access_token=b718f418d1ac4a49aa9b7201a35ed224',
        host = process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr';
    //  host='homekit.1drop.co.kr',


    let URL = process.env.REACT_APP_SITEDATA_COMMENT_DETAIL || '/article/detail';


    const headers = {
        //'host': host,
        //'Cookie': token,
        "Content-Type": "application/json"

    };

    const parser = ([is_loading, rtn]) => {

        return [is_loading, rtn];
    };
    return parser(
        useRequestAPI2ForDetail(
            URL, // url
            params, // params
            headers
        ));

    // return useRequestAPI2ForDetail;
}


export function useGetBoardCommentWrite(seq,login_info) {
    const params = {
        "site_key": login_info.site_key,
        "seq": seq

    };
    const token = 'access_token=b718f418d1ac4a49aa9b7201a35ed224',
        host = process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr';
    //  host='homekit.1drop.co.kr',


    let URL = process.env.REACT_APP_SITEDATA_COMMENT_DETAIL || '/article/detail';

    //const URL = process.env.REACT_APP_SITEDATA_ANALYSIS + '/homekit/admin/api/field/analysis';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/field/analysis';
    const headers = {
        //'host': host,
        //'Cookie': token,
        "Content-Type": "application/json"

    };

    const parser = ([is_loading, rtn]) => {

        return [is_loading, rtn];
    };
    return parser(
        useRequestAPI2ForDetail(
            URL, // url
            params, // params
            headers
        ));

    // return useRequestAPI2ForDetail;
}


export function useGetBoardCommentModify(seq,login_info) {
    const params = {
        "site_key": login_info.site_key,
        "seq": seq

    };
    const token = 'access_token=b718f418d1ac4a49aa9b7201a35ed224',
        host = process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr';
    //  host='homekit.1drop.co.kr',


    let URL = process.env.REACT_APP_SITEDATA_COMMENT_DETAIL || '/article/detail';

    //const URL = process.env.REACT_APP_SITEDATA_ANALYSIS + '/homekit/admin/api/field/analysis';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/field/analysis';
    const headers = {
        //'host': host,
        //'Cookie': token,
        "Content-Type": "application/json"

    };

    const parser = ([is_loading, rtn]) => {

        return [is_loading, rtn];
    };
    return parser(
        useRequestAPI2ForDetail(
            URL, // url
            params, // params
            headers
        ));

    // return useRequestAPI2ForDetail;
}


export function useGetBoardCommentDelete(seq,login_info) {
    const params = {
        "site_key": login_info.site_key,
        "seq": seq

    };
    const token = 'access_token=b718f418d1ac4a49aa9b7201a35ed224',
        host = process.env.REACT_APP_HOST || 'testsrv.1drop.co.kr';
    //  host='homekit.1drop.co.kr',


    let URL = process.env.REACT_APP_SITEDATA_COMMENT_DETAIL || '/article/detail';

    //const URL = process.env.REACT_APP_SITEDATA_ANALYSIS + '/homekit/admin/api/field/analysis';
    //const URL = 'https://homekit.1drop.co.kr/homekit/admin/api/field/analysis';
    const headers = {
        //'host': host,
        //'Cookie': token,
        "Content-Type": "application/json"

    };

    const parser = ([is_loading, rtn]) => {

        return [is_loading, rtn];
    };
    return parser(
        useRequestAPI2ForDetail(
            URL, // url
            params, // params
            headers
        ));

    // return useRequestAPI2ForDetail;
}