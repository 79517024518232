
//import { BoxLoading } from 'react-loadingg';
import { TransverseLoading } from 'react-loadingg';

export default function UntilIsLoading ({is_loading=false, loading_msg='now loading...', children}) {
    return (
        <>
        {
            (is_loading) 
            ? (
            <div> 
                <div style={{position:'relative'}} align='center'>
                    <TransverseLoading 
                        color='blue' size='large' 
                        style={{                            
                            height:'80px',                           
                        }} />
                </div>
                <div style={{paddingTop:'15px'}}><h3>{loading_msg}</h3>  </div>  
            </div>
            )
            : children
        }         
        </>
    )
};