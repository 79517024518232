//import { Typography } from '@mui/material'
import React from 'react';

//import { textSliced } from '__lib/aTools';

import CardBasic  from  '_components_public/CardBasic';
import AreaChart_1 from "_components_chart/AreaChart_1";


const listByPeriod = (by_period) => {
    if (!by_period || Object.keys(by_period).length===0) {
        return [];
    };
    return Object.entries(by_period).map( ([kk,vv]) => (
        {     
            x : kk.slice(2),//kk,                   
            date: kk,
            value : vv.use_count,
            label : kk.slice(5,10),  
            description : `${kk} ${vv.use_count}`,    
        }
        )).sort((a, b) => +(a.date > b.date) || -(a.date) < b.date);
};


export default function CardDist02_CountOfUse({location_analysis, sumOFFieldAnalysis, textLangAnalysisresultbox}) {

    const by_period = location_analysis.periodical;
    const use_by_period = React.useMemo(()=>listByPeriod( by_period), [by_period]);

    const CardBody = (sumOFFieldAnalysis != 0) ? (
            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                }}>
            <AreaChart_1 width={480} height={240} data={use_by_period} dot={false} line_type='monotoneX'/>
            </div>) :
        (<div style={{padding:'0 20px 0 20px'}}>
            <div style={{width: "420px", height: "260px", "vertical-align": "middle", display: "table-cell", "text-align": "center"}}>{textLangAnalysisresultbox.error1}</div>
        </div>)
    

    return (
        <>
        <CardBasic card_title={textLangAnalysisresultbox.title} card_body={CardBody} style={{position: 'relative', width:515, height:310}}/>
        </>

    )
}